import React, { Component, Suspense } from 'react';
import styles from './styles/HomePage.module.css';
import { inject, observer } from 'mobx-react';
import LoginCheck from '../components/LoginCheck';

@inject("login", "links", "pages")
@observer
class Setting extends Component {

    constructor() {
        super();
        this.state = {
            isMobile: window.innerWidth < 767,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }


    updateIsMobile = () => {

    }



    render() {


        return (
            <LoginCheck loggedin={true}>
                <div>
                Setting
                </div>
            </LoginCheck>
        );


    }
}
export default Setting;

