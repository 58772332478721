import { React, StrictMode } from "react";

import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'mobx-react'
import { BrowserRouter, StaticRouter } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import { HelmetProvider } from 'react-helmet-async';

import stores from './stores';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import './components/AccessibilityToolBar'

import DataContextProvider from './context/DataContext'

require('dotenv').config()

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

//render app to root
root.render(
  <StrictMode>
    <Provider
      login={stores.login}
      links={stores.links}
      pages={stores.pages}
      language={stores.language}
    >
      <DataContextProvider>
        <ParallaxProvider>
          <BrowserRouter>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </BrowserRouter>
        </ParallaxProvider>
      </DataContextProvider>
    </Provider >
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
