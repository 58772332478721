import { observable, configure, runInAction, action, makeAutoObservable } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';
import './Login'
import Login from './Login';

configure({ enforceActions: true });


class Language {

    //Translations
    @observable SiteLanguage = Login.SiteLanguage
    @observable TrInvalidAddress = "כתובת שגויה"
    @observable TrMissingField = "שדה חסר"
    @observable TrSend = "שליחה"
    @observable TrMessage = "הודעה"
    @observable TrPhone = "טלפון"
    @observable TrFullName = "שם מלא"
    @observable TrEmail = `דוא"ל`
    @observable TrAccessibilityStatement = "הצהרת נגישות"


    constructor() {
        this.GetTranslations();
        makeAutoObservable(this);
    };

    GetTranslations() {
        if (this.SiteLanguage === 'en') {
            this.TrInvalidAddress = "Invalid address"
            this.TrMissingField = "Misssing Filed"
            this.TrSend = "Send"
            this.TrMessage = "Message"
            this.TrPhone = "Phone"
            this.TrFullName = "Full name"
            this.TrEmail = "Email"
            this.TrAccessibilityStatement = "Acessibility statement"
        }
    }
};



export default new Language();




