import React from "react";
import styles from './WeekFavourites.module.css';
import SectionTitle from "../SectionTitle";
import WeekFavouritesItem from "./WeekFavouritesItem";
import clientConfig from "../../client-config";
import { inject, observer } from 'mobx-react';
import { toJS, runInAction } from 'mobx';
import axios from 'axios';
import parse from 'html-react-parser';

@inject("login", "links", "pages")
@observer
class WeekFavourites extends React.Component {

    constructor() {
        super();
        this.state = {
            Filtering: true,
            Dishes: [],
            TopItems: []
        };
    }

    componentDidMount() {
        // this.GetDishes(0)
        this.get_week_favourites()
    }


    get_week_favourites() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/get_week_favourites`, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                // console.log(res.data)
                runInAction(() => {
                    this.FilterAsync(res.data)
                });
            })
            .catch(err => {
                console.log(err)
            })
    }


    GetDishes(offset) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const PerPage = 100
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/dishes?_embed&acf_format=standard&per_page=${PerPage}&offset=${offset}`)
            .then(res => {
                runInAction(() => {
                    const Arr1 = this.state.Dishes
                    const Arr2 = res.data
                    const Arr3 = Arr1.concat(Arr2);
                    let RemoveDuplicates = [...new Set(Arr3)];
                    if (res.data.length == PerPage) {
                        const newOffset = offset + PerPage
                        this.setState({ Dishes: Arr3 })
                        this.GetDishes(newOffset)
                        return
                    }
                    this.FilterAsync(RemoveDuplicates)
                });
            })
            .catch(err => {
                console.log('LoadPage ERR: ' + err)
            })
    };


    FilterAsync = async (Arr3) => {
        this.setState({
            Filtering: true
        })
        // this.props.pages.updating = true

        let TopItems = []// מנה ראשונה


        // const promises = Arr3.map((item, index) => {
        //     if (item.acf.likes !== '') {
        //         TopItems.push(item)
        //     }
        // })

        const promises = Arr3.sort(function (a, b) {
            return a.acf.likes - b.acf.likes;
        });

        await Promise.all(promises).then((values) => {
            this.setState({
                Filtering: false,
                TopItems: values.reverse()
            })
        });
    }


    render() {
        let WeekFavourites = <>
            <WeekFavouritesItem loading={true} />
            <WeekFavouritesItem loading={true} />
            <WeekFavouritesItem loading={true} />
        </>

        if (this.state.Filtering == false) {
            WeekFavourites = this.state.TopItems.map((item, index) => {
              
                let Title = item.title.rendered
                if (Title !== '') {
                    Title = parse(Title)
                }
                if (index < 3) {
                    return (
                        <WeekFavouritesItem
                            DishName={Title}
                            Number={item.acf.likes}
                            DiningRoom={item.Categories[0].name} />
                    )
                }

            })




        }
        return (
            <div className={styles.MainContainer} onClick={this.props.onClick}>
                <SectionTitle Title="המאכלים האהובים השבוע" />
                {WeekFavourites}
            </div>
        )
    }
}
export default WeekFavourites;