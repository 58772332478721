import React, { Component, Suspense } from 'react';
import Styles from './Notifications.module.css';
import { inject, observer } from 'mobx-react';
import { runInAction } from 'mobx';
import clientConfig from '../../client-config';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

@inject("login", "links", "pages")
@observer
class Notification extends Component {

    constructor() {
        super();
        this.state = {
            loading: false
        };
    }

    componentDidMount() {

    }


    delete_post() {
        const SelectedItemId = this.props.pages.SelectedInfoPageId
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = this.props.id

        const wordPressSiteUrl = clientConfig.siteUrl;
        this.setState({
            loading: true
        })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/delete_post`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    this.props.pages.GetNotifications()
                    if (document.getElementById('get_notifications')) {
                        document.getElementById('get_notifications').click()
                    }
                    this.setState({
                        loading: false
                    })
                });
            })
            .catch(err => {

            })
    }


    EditNotificationPopup = () => {
        this.props.pages.NewInfoItem = true
        this.props.pages.CurrentScrollPosition = document.body.getBoundingClientRect().top
        window.scrollTo({ top: 0, left: 0, behavior: "instant" })
        this.props.pages.EditNotificationPopup = true
        this.props.pages.CustomAlertTitle = this.props.Title
        this.props.pages.CustomAlertIcon = this.props.IconName
        this.props.pages.CustomAlertStartDate = this.props.startDate
        this.props.pages.CustomAlertStartTime = this.props.startTime
        this.props.pages.CustomAlertEndDate = this.props.endDate
        this.props.pages.CustomAlertEndTime = this.props.endTime
        this.props.pages.SelectedAlertId = this.props.id
      
        // click button in EditNotificationPopup.js
        if (document.getElementById('set_edit_notification_items')) {
            document.getElementById('set_edit_notification_items').click()
        }
    }

    render() {

        let BackgroundColor = this.props.login.primaryColor
        if (this.props.BackColor == 1) {
            BackgroundColor = this.props.login.primaryColor
        }
        if (this.props.BackColor == 2) {
            BackgroundColor = this.props.login.secondaryColor
        }
        if (this.props.BackColor == 3) {
            BackgroundColor = this.props.login.tertiaryColor
        }
        const style = {
            backgroundImage: `url(${this.props.links.NotificationBack})`,
            backgroundColor: BackgroundColor
        }

        const trash = <svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4.6V12.7C12 13.0448 11.8645 13.3754 11.6234 13.6192C11.3823 13.863 11.0553 14 10.7143 14H4.28571C3.94472 14 3.6177 13.863 3.37658 13.6192C3.13546 13.3754 3 13.0448 3 12.7V4.6M4.92857 3.6V2.3C4.92857 1.95522 5.06403 1.62456 5.30515 1.38076C5.54627 1.13696 5.87329 1 6.21429 1H8.78571C9.12671 1 9.45373 1.13696 9.69485 1.38076C9.93597 1.62456 10.0714 1.95522 10.0714 2.3V3.6" stroke="#E9EAEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 4H2.44444H14" stroke="#E9EAEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        const EditIcon = <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 16H16.8849" stroke="#E9EAEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.9426 1.54434C13.2911 1.1958 13.7638 1 14.2567 1C14.5008 1 14.7425 1.04807 14.968 1.14147C15.1934 1.23487 15.3983 1.37176 15.5709 1.54434C15.7435 1.71692 15.8804 1.92179 15.9738 2.14728C16.0672 2.37276 16.1152 2.61443 16.1152 2.85849C16.1152 3.10255 16.0672 3.34422 15.9738 3.5697C15.8804 3.79519 15.7435 4.00006 15.5709 4.17264L4.61964 15.1239L1.11523 16L1.99134 12.4956L12.9426 1.54434Z" stroke="#E9EAEB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        let DelItem = null
        let EditItem = null
        if (this.props.UserType == 'webapp_admin' || this.props.UserType == 'webapp_master_admin') {
            DelItem = <button onClick={this.delete_post.bind(this)} className={Styles.Trash}>{trash}</button>
            EditItem = <button onClick={this.EditNotificationPopup.bind(this)} className={Styles.Edit}>{EditIcon}</button>
        }

        return (
            <div className={Styles.MainContainer} >

                <div className={Styles.Container}>
                    <div className={Styles.Notification} style={style}>
                        {this.state.loading ? <CircularProgress
                            style={{ position: 'absolute', top: '6px', left: '5px' }}
                            sx={{
                                color: (theme) => { '#221b32' },
                            }} />
                            : null
                        }
                        {DelItem}
                        {EditItem}
                        <div className={Styles.IconContainer}><img style={{ width: this.props.IconWidth }} src={this.props.Icon} /></div>
                        <div className={Styles.TextContainer}>
                            <h1>{this.props.Title}</h1>
                        </div>
                    </div>
                </div>
            </div>

        );


    }
}
export default Notification;

