import React from "react";
import styles from './CloseOpenButton.module.css';
import { inject, observer } from 'mobx-react';
import axios from "axios";
import { observable, configure, runInAction, action, toJS, makeAutoObservable } from 'mobx';
import clientConfig from "../../client-config";

@inject("login", "links", "pages")
@observer
class ShowHideButton extends React.Component {
    constructor() {
        super();
        this.state = {
            IsopenToday: null,
            loading: false,
        };
    }

    componentDidMount() { }

    @action
    ClosOpenDate() {
        const CurrentState = this.props.pages.IsopenToday
        const SelectedDate = this.props.pages.SelectedDate

        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.current_state = CurrentState
        data_to_post.selected_date = SelectedDate
        data_to_post.page_id = this.props.pages.CloseDatePageId //עמוד ימים סגורים


        this.props.pages.updating = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/close_open_date`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.props.pages.updating = false
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.props.pages.updating = false
                });
            })
    }

    @action
    ToggleBt = () => {
        this.ClosOpenDate()
        const CurrentState = this.props.pages.IsopenToday


        const CloseDates = toJS(this.props.pages.SelectedPageCloseDates)

        runInAction(() => {
            if (CurrentState == true) {
                const newCloseDate = { close_date: this.props.pages.SelectedDate }
                this.props.pages.SelectedPageCloseDates = CloseDates.concat(newCloseDate);
            }
            if (CurrentState == false) {
                const RemoveSelectedDate = CloseDates.filter(item => item.close_date !== this.props.pages.SelectedDate);
                this.props.pages.SelectedPageCloseDates = RemoveSelectedDate
            }
        });



    }

    render() {



        const buttonStyle = {
            backgroundColor: !this.props.pages.IsopenToday ? '#C3C6C9' : "#49C9D1"
        }
        const HandleStyle = {
            borderColor: !this.props.pages.IsopenToday ? '#C3C6C9' : "#49C9D1",
            right: !this.props.pages.IsopenToday ? '0' : "14px",
        }

        let Content = <div className={styles.MainContainer} style={{ marginTop: this.props.MarginTop }} onClick={this.state.loading ? null : this.ToggleBt.bind(this)}>
            <div className={styles.Container} style={buttonStyle}>
                <div className={styles.BtHandel} style={HandleStyle}></div>
            </div>
        </div>

        if (this.state.IsopenToday !== null) {
            const buttonStyleB = {
                backgroundColor: this.state.IsopenToday ? '#C3C6C9' : "#49C9D1"
            }
            const HandleStyleB = {
                borderColor: this.state.IsopenToday ? '#C3C6C9' : "#49C9D1",
                right: this.state.IsopenToday ? '0' : "14px",
            }
            Content = <div className={styles.MainContainer} style={{ marginTop: this.props.MarginTop }} onClick={this.state.loading ? null : this.ToggleBt.bind(this)}>
                <div className={styles.Container} style={buttonStyleB}>
                    <div className={styles.BtHandel} style={HandleStyleB}></div>
                </div>
            </div>
        }

        return (
            <>
                {Content}
            </>
        )
    }
}
export default ShowHideButton;