import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action, runInAction } from 'mobx';

import styles from './styles/AdminLogin.module.css';
import Star from '../components/Star'
import EnterButton from '../components/EnterButton';
import MuiTextFieldPassword from '../components/MuiAssets/MuiTextFieldPassword';
import clientConfig from '../client-config';
import axios from 'axios';

@inject("login", "links")
@observer
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            EnterButtonClicked: false,
            password: ''
        }
    }


    @action
    Login = () => {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const loginData = {
            username: this.state.password,
            password: this.state.password,
        };

        this.loading = true
        this.setState({ EnterButtonClicked: true })
        axios.post(`${wordPressSiteUrl}/wp-json/jwt-auth/v1/token`, loginData)
            .then(res => {
              
                localStorage.setItem('user_roles', res.data.user_roles);
                localStorage.setItem('token', res.data.token);
            
                this.props.login.loggedIn = true
                this.props.login.UserRoles = res.data.user_roles

                this.props.SetPage('home')
            })

            .catch(err => {
                this.setState({ EnterButtonClicked: false })
                console.log(err)
            })
    };




    EnterButtonClicked = () => {
        this.setState({ EnterButtonClicked: true })
        this.Login()
        // this.props.LoginButtonClicked(this.state.password)
    }

    OnPsswordChange = (e) => {
        this.setState({ password: e })
    }

    render() {

        let MainContainerStyle = {
            backgroundImage: `url(${this.props.links.FoodPattern})`
        }

        let LoginImageAndStarsStyle = null
        let EnterButtonStyle = null
        let LoginTextStyle = { top: '420px' }
        let SpinnerStyle = { opacity: 0 }

        if (this.state.EnterButtonClicked) {
            LoginImageAndStarsStyle = { top: '30vh' }
            EnterButtonStyle = { opacity: 0 }
            SpinnerStyle = { opacity: 100 }
            LoginTextStyle = { top: '540px' }
        }

        return (
            <div className={styles.MainContainer} style={MainContainerStyle}>
                <div className={styles.LoginConatiner}>
                    <div className={styles.LoginImageAndStars} style={LoginImageAndStarsStyle} >
                        <div className={styles.StarsContainer}>
                            <Star size={25} className={styles.Star1 + ' ' + styles.Postion1} />
                            <Star size={25} className={styles.Star2 + ' ' + styles.Postion2} />
                            <Star size={15} className={styles.Star1 + ' ' + styles.Postion3} />
                            <Star size={15} className={styles.Star2 + ' ' + styles.Postion4} />
                            <Star size={25} className={styles.Star2 + ' ' + styles.Postion5} />
                        </div>
                        <div className={styles.Spinner} style={SpinnerStyle}>
                            <img src={this.props.links.Spinner}></img>
                        </div>
                        <div className={styles.LogoMenu}><img src={this.props.links.LogoMenu}></img></div>
                    </div>
                    <div className={styles.LoginText} style={LoginTextStyle}>
                        <MuiTextFieldPassword password={this.state.password} onChange={this.OnPsswordChange.bind(this)} />
                    </div>
                    <EnterButton onClick={this.Login.bind(this)} style={EnterButtonStyle} />
                </div>
            </div>
        );
    }
}

export default Login;
