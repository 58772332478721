import React, { Fragment } from "react";
import TextField from "@mui/material/TextField";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import parse from 'html-react-parser';


class MuiAccordion extends React.Component {

    constructor() {
        super();
        this.state = {
            text: ''
        }
    }


    componentDidMount() {

    }

    EditItemPopUp = () => {
        this.props.EditItemPopUp()
    }

    render() {

        const primaryColor = '#221b32'

        let opacity = '1'
        if (this.props.value == '') {
            opacity = '0'
        }

        let Erropacity = '0'
        if (this.props.Err !== '') {
            Erropacity = '1'
        }

        let FieldDescriptionStyle = { fontSize: '11px', color: '#979797', transition: '.2s', opacity: opacity }
        let ErrStyle = { fontSize: '11px', color: 'red', transition: '.2s', opacity: Erropacity }

        const EditIcon = <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 16H16.8849" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.9426 1.54434C13.2911 1.1958 13.7638 1 14.2567 1C14.5008 1 14.7425 1.04807 14.968 1.14147C15.1934 1.23487 15.3983 1.37176 15.5709 1.54434C15.7435 1.71692 15.8804 1.92179 15.9738 2.14728C16.0672 2.37276 16.1152 2.61443 16.1152 2.85849C16.1152 3.10255 16.0672 3.34422 15.9738 3.5697C15.8804 3.79519 15.7435 4.00006 15.5709 4.17264L4.61964 15.1239L1.11523 16L1.99134 12.4956L12.9426 1.54434Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        let Edit = null

        if (this.props.UserType == 'webapp_admin') {
            Edit = <div style={{ marginLeft: '10px' }} onClick={this.EditItemPopUp.bind(this)}>
                {EditIcon}
            </div>
        }

        return (
            <Fragment key={this.props.id}>
                <Accordion
                    sx={{
                        backgroundColor: this.props.backgroundColor,
                        color: 'white',
                        maxWidth: '600px',
                        width: '90%',
                        borderRadius: '5px',
                        boxShadow: 'none',
                        margin: '10px !important',
                        '&.Mui-expanded': {
                            margin: '10px !important'
                        },
                        '&. MuiAccordionSummary-conten': {
                            margin: '0px !important'
                        }
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        // aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        {Edit}
                        <div style={{ fontWeight: '600' }}>{parse(this.props.Title)}</div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="ElergenimDetails" style={{ direction: 'rtl', textAlign: 'right' }}>
                            {this.props.Details ? this.props.Details :
                                <>
                                    <strong>יצרן: </strong>
                                    {this.props.manufacturer}
                                    <br />
                                    <strong>מכיל: </strong>
                                    {this.props.contain}
                                    <br />
                                    <strong>עלול להכיל: </strong>
                                    {this.props.mayContain}
                                    <br />
                                    {this.props.cashrot == '' ? null :
                                        <>
                                            <strong>כשרות: </strong>
                                            {this.props.cashrot}
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>

                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: this.props.marginBottom == '' ? 'inherit' : this.props.marginBottom }}>
                    <div style={FieldDescriptionStyle}>{this.props.FieldDescription}</div>
                    <div style={ErrStyle}>{this.props.Err}</div>
                </div>
            </Fragment>
        )
    }
}
export default MuiAccordion;