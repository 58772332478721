import React, { Component } from 'react';
import styles from './MuiTimePicker.module.css';
import he from "date-fns/locale/he";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
// import TimePicker from 'react-time-picker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';

class MuiTimePicker extends React.Component {

    constructor() {
        super();
        this.state = {
            DaySelected: null,
            DayHeb: '',
            MounthHeb: '',
            Year: '',
            DayNumber: '',
        }
    }


    componentDidMount() {

    }

    onChange = () => {
        console.log('aaaa')
    }

    SelectDay = (e) => {
        const DaySelected = new Date(e)
        const day = DaySelected.getDay();
        if (day === 0) { this.setState({ DayHeb: 'ראשון' }) }
        if (day === 1) { this.setState({ DayHeb: 'שני' }) }
        if (day === 2) { this.setState({ DayHeb: 'שלישי' }) }
        if (day === 3) { this.setState({ DayHeb: 'רביעי' }) }
        if (day === 4) { this.setState({ DayHeb: 'חמישי' }) }
        if (day === 5) { this.setState({ DayHeb: 'שישי' }) }
        if (day === 6) { this.setState({ DayHeb: 'שבת' }) }

        const mounth = DaySelected.getMonth()
        if (mounth === 0) { this.setState({ MounthHeb: 'ינואר' }) }
        if (mounth === 1) { this.setState({ MounthHeb: 'פברואר' }) }
        if (mounth === 2) { this.setState({ MounthHeb: 'מרץ' }) }
        if (mounth === 3) { this.setState({ MounthHeb: 'אפריל' }) }
        if (mounth === 4) { this.setState({ MounthHeb: 'מאי' }) }
        if (mounth === 5) { this.setState({ MounthHeb: 'יוני' }) }
        if (mounth === 6) { this.setState({ MounthHeb: 'יולי' }) }
        if (mounth === 7) { this.setState({ MounthHeb: 'אוגוסט' }) }
        if (mounth === 8) { this.setState({ MounthHeb: 'ספטמבר' }) }
        if (mounth === 9) { this.setState({ MounthHeb: 'אוקטובר' }) }
        if (mounth === 10) { this.setState({ MounthHeb: 'נובמבר' }) }
        if (mounth === 11) { this.setState({ MounthHeb: 'דצמבר' }) }
        this.setState({
            DaySelected: e,
            Year: DaySelected.getFullYear(),
            DayNumber: DaySelected.getDate(),
        })
        this.props.DaySelected(e)
    }


    render() {

        const primaryColor = '#221b32'
        const secondaryColor = '#27738a'
        const tertiaryColor = '#faa73f'



        const today = new Date();
        return (
            <>

                <div className={styles.TimePickerContiner}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MultiSectionDigitalClock
                            sx={{
                                direction: 'ltr !important',
                            }}
                        />
                    </LocalizationProvider>
                    
                    {/* <div className={styles.ApproveDaySelect}>
                        <div onClick={this.props.ApproveDaySelect} className={styles.Approve}>אישור</div>
                        <div onClick={this.props.CancelDaySelect} className={styles.Cancel}>ביטול</div>
                    </div> */}
                </div>
            </>
        )
    }
}
export default MuiTimePicker;