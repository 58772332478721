import React, { Component, Suspense } from 'react';
import styles from './styles/CroneJobTest.module.css';
import { inject, observer } from 'mobx-react';
import LoginCheck from '../components/LoginCheck';
import clientConfig from '../client-config';
import axios from 'axios';
import { toJS, runInAction } from 'mobx';

@inject("login", "links", "pages")
@observer
class CroneJobTest extends Component {

    constructor() {
        super();
        this.state = {
            pageData: ''
        };
    }

    componentDidMount() {
        this.GetPageData()
       
        // const user_roles = cookies.get('user_roles');
        // console.log(user_roles)
    }



    GetPageData() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const pageId = 10978
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${pageId}?acf_format=standard`)
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.setState({
                        pageData: res.data.acf.text
                    })
                });
            })
            .catch(err => {
                console.log('LoadPage ERR: ' + err)
            })
    };



    render() {
    

        return (
            <LoginCheck loggedin={true}>
                <div className={styles.MainContainer}>
                    <h1> CroneJobTest</h1>
                    <div className={styles.DataTitle}>
                        last update
                    </div>

                    <div className={styles.Data}>
                        {this.state.pageData}
                    </div>
                </div>
            </LoginCheck>
        );


    }
}
export default CroneJobTest;

