import React, { Component } from "react";


class Star extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <div className={this.props.className} >
                <svg width={this.props.size} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.516373 20.1292C13.3443 24.4051 15.4822 26.5431 19.7582 39.371C24.0341 26.5431 26.1721 24.4051 39 20.1292C26.1721 15.8532 24.0341 13.7152 19.7582 0.887329C15.4822 13.7152 13.3443 15.8532 0.516373 20.1292Z" fill="currentColor" />
                </svg>
            </div>
        );
    }
}

export default Star;