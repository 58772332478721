import React, { Suspense, lazy } from 'react';
import { StyleRoot } from 'radium';
import { Switch, Route } from "react-router-dom";

import MainScreen from './screens/MainScreen';

import CroneJobTest from './screens/CroneJobTest';

import './App.scss';
import './App_en.scss';
import './App_he.scss';
import './JsComposer.scss';
import '../src/assets/css/js_composer.min.css';
import './CustomBootStrap.scss'

function App() {
  return (
    <StyleRoot>

      <div className="App">


        <Switch>
       
          <Route path="/cron_job_test">
            <CroneJobTest />
          </Route>

          <Route path="/" forceRefresh={true}>  <MainScreen />  </Route>

        </Switch>



      </div>
    </StyleRoot>
  );
}

export default App;
