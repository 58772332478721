import React from "react";
import Select from 'react-dropdown-select';


class DropdownSelect extends React.Component {

    constructor() {
        super();
        this.state = {
            DropdownOpen: false
        }
    }


    componentDidMount() {

    }
    onChange = (e) => {
        e.preventDefault
        this.props.onChange(e)
    }

    Clear = () => {
        this.ref.clearAll()
    }

    onDropdownOpen = () => {
        this.setState({ DropdownOpen: true })
    }

    onDropdownClose = () => {
        this.setState({ DropdownOpen: false })
    }

    onSet = (values) => {
        // console.log(values)
        // const newValue = values.map((val) => ({ value: val.email, label: val.name }));

        // return this.setState({
        //     values: [...this.state.values, ...newValue]
        // });
    };



    render() {

        let className = 'FormDropdownSelect'

        if (this.props.SiteLang == 'en') {
            className = 'FormDropdownSelectEn'
        }

        const Handel = <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6.24074 7.1142C6.63984 7.57981 7.36016 7.57981 7.75926 7.1142L13 1" stroke="#49C9D1" stroke-width="2" />
        </svg>

        const HandelOpen = <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 8L7.75926 1.8858C7.36016 1.42019 6.63984 1.42019 6.24074 1.8858L1 8" stroke="#49C9D1" stroke-width="2" />
        </svg>

        let opacity = '1'
        if (this.ref?.state.values == '') {
            opacity = '0'
        }

        let Erropacity = '0'
        if (this.props.Err !== '') {
            Erropacity = '1'
        }

        let FieldDescriptionStyle = { fontSize: '11px', color: '#979797', transition: '.2s', opacity: opacity }
        let ErrStyle = { fontSize: '11px', color: 'red', transition: '.2s', opacity: Erropacity }

        return (
            <div className={this.props.className + ' ' + className} >

                {/* Hidden Clear button */}
                <div style={{ position: 'absolute', opacity: '0', zIndex: '-5000' }} onClick={this.Clear.bind(this)} id={`clear_${this.props.name}`}>a</div>
                <div style={{ position: 'absolute', opacity: '0', zIndex: '-5000' }} onClick={() => this.props.SetValue(this.props.options)} id={`set_${this.props.name}`}>a</div>
                {/* <p>Values can be added from outside <button href={() => null} onClick={() => this.props.SetValue(this.props.options)}>&raquo; set</button></p> */}
                <Select
                    disabled={this.props.disabled}
                    ref={ref => (this.ref = ref)}
                    // clearable
                    keepSelectedInList={false}
                    multi={this.props.multi}
                    searchable={false}
                    loading={this.props.loading}
                    direction={'rtl'}
                    className={this.props.ChildclassName}
                    values={this.props.values}
                    placeholder={this.props.placeholder}
                    labelField="name"
                    valueField="id"
                    options={this.props.options}
                    onChange={this.onChange.bind(this)}
                    closeOnSelect={true}
                    dropdownHandleRenderer={
                        ({ props, state, methods }) => {
                            return (
                                <>{state.dropdown ? <div>{HandelOpen}</div> : <div>{Handel}</div>}</>
                            )
                        }
                    }
                    onDropdownOpen={this.onDropdownOpen.bind(this)}
                    onDropdownClose={this.onDropdownClose.bind(this)}
                />
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div style={FieldDescriptionStyle}>{this.props.FieldDescription}</div>
                    <div style={ErrStyle}>{this.props.Err}</div>
                </div>
            </div>
        )
    }
}
export default DropdownSelect;