import React, { Component, Suspense } from 'react';
import Styles from './SearchInput.module.css';
import { inject, observer } from 'mobx-react';
import { TextField, Box } from '@mui/material';


@inject("login", "links", "pages")
@observer
class SearchInput extends Component {

    constructor() {
        super();
        this.state = {
            isMobile: window.innerWidth < 767,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }


    updateIsMobile = () => {

    }

    onChange = (e) => {
        this.props.onChange(e)
    }


    render() {


        return (
            <div className={Styles.MainContainer}>
                <div className={Styles.Container}>
                    <TextField
                        width="100%"
                        sx={{
                            borderRadius: '18px',
                            height: '55px',
                            '& .MuiInputBase-root': {
                                width: '100%',
                            },
                            "& fieldset": { border: 'none' },

                        }}
                        className={Styles.TextField}
                        hiddenLabel
                        placeholder="מה נאכל היום?"
                        size="small"
                        value={this.props.value}
                        name={this.props.name}
                        onChange={this.onChange.bind(this)}
                        InputProps={{
                            startAdornment: (
                                <Box className={Styles.IconSearchContainer} >
                                    <div onClick={this.props.onClick} className={Styles.IconSearch}>
                                        <img src={this.props.links.IconSearch} />
                                    </div>
                                </Box>
                            ),
                        }}
                    />
                </div>

            </div>

        );


    }
}
export default SearchInput;

