import login from './Login';
import links from './Links';
import pages from './Pages';
import language from './Language';

const stores = {
    login,
    links,
    pages,
    language
};

export default stores