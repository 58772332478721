import React, { Component, Suspense } from 'react';
import styles from './styles/DiningRoom.module.css';
import { inject, observer } from 'mobx-react';
import LoginCheck from '../components/LoginCheck';
import SectionTitle from '../components/SectionTitle';

@inject("login", "links", "pages")
@observer
class Contact extends Component {

    constructor() {
        super();
        this.state = {
            isMobile: window.innerWidth < 767,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile);
    }


    updateIsMobile = () => {

    }



    render() {


        return (
            <LoginCheck loggedin={true}>
                <div className={styles.MainContainer} style={{ paddingTop: this.props.PaddingTop }}>
                    <SectionTitle Title="חדר אוכל" MarginBottom="15px" MarginTop="-15px" />
                    <div className={styles.ContactInfo}>
                        <h1>ליצירת קשר ניתן לפנות:</h1>
                        <ul>
                            <li>דרך מערכת ה-SNOW</li>
                            <li>באמצעות חיוג אל מרכז שירות מת״ל,
                                במספר: 0733352000</li>
                        </ul>
                    </div>
                </div>
            </LoginCheck>
        );


    }
}
export default Contact;

