import React from "react";
import styles from './EditItemPopup.module.css';
import Cookies from 'universal-cookie';
import { inject, observer } from 'mobx-react';
import clientConfig from "../../client-config";
import axios from "axios";
import { observable, configure, runInAction, action, toJS, makeAutoObservable } from 'mobx';
import SectionTitle from "../SectionTitle";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import MuiTextField from "../MuiAssets/MuiTextField"
import MuiCircularProgress from "../MuiAssets/MuiCircularProgress"
import DateRangePicker from '../MuiAssets/DateRangePicker'
import TimePicMuiTimePickerer from "../MuiAssets/MuiTimePicker";
import MuiTimePicker from "../MuiAssets/MuiTimePicker";
import Moment from 'react-moment';
import moment from 'moment';
import parse from 'html-react-parser';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';



@inject("login", "links", "pages")
@observer
class EditItemPopup extends React.Component {
    constructor() {
        super();
        this.state = {
            ElergenimTitle: '',
            ElergenimManufacturer: '',
            ElergenimContain: '',
            ElergenimMayContain: '',
            ElergenimDetails: '',
            ElergenimCashrot: '',
            ElergenimTitleErr: '',
            ElergenimManufacturerErr: '',
            ElergenimContainErr: '',
            ElergenimMayContainErr: '',
            ElergenimDetailsErr: '',
            ElergenimCashrotErr: ''
        };
    }


    componentDidMount() {

    }

    AddElergenimItem = () => {
        let Err = false
        const ElergenimTitle = this.state.ElergenimTitle
        const ElergenimDetails = this.state.ElergenimDetails
        const ElergenimManufacturer = this.state.ElergenimManufacturer
        const ElergenimContain = this.state.ElergenimContain
        const ElergenimMayContain = this.state.ElergenimMayContain
        const ElergenimCashrot = this.state.ElergenimCashrot

        if (ElergenimTitle == '') {
            this.setState({
                ElergenimTitleErr: 'נא למלא כותרת'
            })
            Err = true
        }
        if (ElergenimManufacturer == '') {
            this.setState({
                ElergenimManufacturerErr: 'נא למלא שם יצרן'
            })
            Err = true
        }
        if (ElergenimContain == '') {
            this.setState({
                ElergenimContainErr: 'נא למלא פרטים'
            })
            Err = true
        }
        if (ElergenimMayContain == '') {
            this.setState({
                ElergenimMayContainErr: 'נא למלא פרטים'
            })
            Err = true
        }
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.elergenim_title = ElergenimTitle
        data_to_post.elergenim_manufacturer = ElergenimManufacturer
        data_to_post.elergenim_contain = ElergenimContain
        data_to_post.elergenim_may_contain = ElergenimMayContain
        data_to_post.elergenim_cshrot= ElergenimCashrot

        if (Err == false) {
            this.create_elergenim_page(data_to_post)
        }
    }


    @action
    create_elergenim_page(data_to_post) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/create_elergenim_page`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    this.props.pages.updating = false
                    this.setState({ updatind: false })
                    this.CloseEdit()
                    if (document.getElementById('RefreshPage')) {
                        document.getElementById('RefreshPage').click()
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }


    @action
    onChangeTextField = (e) => {
        const Name = e.target.name
        const Value = e.target.value
        this.props.pages[e.target.name] = e.target.value
        this.setState({
            [Name]: Value,
            [`${Name}Err`]: '',
        })
    }

    SaveData = () => {

        let Err = false
        const ElergenimTitleCopy = this.props.pages.ElergenimTitleCopy
        const ElergenimTitle = this.state.ElergenimTitle
        const ElergenimTitleNeedUpdate = ElergenimTitle !== ElergenimTitleCopy
        if (ElergenimTitle == '') {
            this.setState({
                ElergenimTitleErr: 'נא למלא כותרת'
            })
            Err = true
        }

        const ElergenimManufacturerCopy = this.props.pages.ElergenimManufacturerCopy
        const ElergenimManufacturer = this.state.ElergenimManufacturer
        const ElergenimManufacturerNeedUpdate = ElergenimManufacturer !== ElergenimManufacturerCopy

        if (ElergenimManufacturer == '') {
            this.setState({
                ElergenimManufacturerErr: 'נא למלא שם יצרן'
            })
            Err = true
        }

        const ElergenimContainCopy = this.props.pages.ElergenimContainCopy
        const ElergenimContain = this.state.ElergenimContain
        const ElergenimContainNeedUpdate = ElergenimContain !== ElergenimContainCopy
        if (ElergenimContain == '') {
            this.setState({
                ElergenimContainErr: 'נא למלא פרטים'
            })
            Err = true
        }

        const ElergenimMayContainCopy = this.props.pages.ElergenimMayContainCopy
        const ElergenimMayContain = this.state.ElergenimMayContain
        const ElergenimMayContainNeedUpdate = ElergenimMayContain !== ElergenimMayContainCopy
        if (ElergenimMayContain == '') {
            this.setState({
                ElergenimMayContainErr: 'נא למלא פרטים'
            })
            Err = true
        }
        // const ElergenimMayContain = this.state.ElergenimMayContain

        const ElergenimCashrotCopy = this.props.pages.ElergenimCashrotCopy
        const ElergenimCashrot = this.state.ElergenimCashrot
        const ElergenimCashrotNeedUpdate = ElergenimCashrot !== ElergenimCashrotCopy

        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = this.props.pages.SelectedElergenimPageId
        data_to_post.elergenim_title = ElergenimTitle
        data_to_post.elergenim_title_need_update = ElergenimTitleNeedUpdate

        data_to_post.elergenim_manufacturer = ElergenimManufacturer
        data_to_post.elergenim_manufacturer_need_update = ElergenimManufacturerNeedUpdate

        data_to_post.elergenim_contain = ElergenimContain
        data_to_post.elergenim_contain_need_update = ElergenimContainNeedUpdate

        data_to_post.elergenim_may_contain = ElergenimMayContain
        data_to_post.elergenim_may_contain_need_update = ElergenimMayContainNeedUpdate

        data_to_post.elergenim_cashrot = ElergenimCashrot
        data_to_post.elergenim_elergenim_cashrot_need_update = ElergenimCashrotNeedUpdate

        if (Err == false) {
            this.update_elergenim_page(data_to_post)
        }
    }


    @action
    update_elergenim_page(data_to_post) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/update_elergenim_page`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    this.props.pages.updating = false
                    this.setState({ updatind: false })
                    this.CloseEdit()
                    if (document.getElementById('RefreshPage')) {
                        document.getElementById('RefreshPage').click()
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }

    @action
    delete_post() {
        const SelectedItemId = this.props.pages.SelectedElergenimPageId
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = SelectedItemId

        const wordPressSiteUrl = clientConfig.siteUrl;

        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/delete_post`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    this.props.pages.updating = false
                    this.CloseEdit()
                    if (document.getElementById('RefreshPage')) {
                        document.getElementById('RefreshPage').click()
                    }
                });
            })
            .catch(err => {

            })
    }

    @action
    CloseEdit = () => {
        window.scrollTo({ top: -this.props.pages.CurrentScrollPosition, left: 0, behavior: "instant" })
        this.props.pages.ElergenimTitle = ''
        this.props.pages.ElergenimDetails = ''
        this.props.pages.ElergenimManufacturer = ""
        this.props.pages.ElergenimContain = ""
        this.props.pages.ElergenimMayContain = ""
        this.props.pages.SelectedElergenimPageId = ''

        this.setState({
            ElergenimTitle: '',
            ElergenimDetails: '',
            ElergenimManufacturer: '',
            ElergenimContain: '',
            ElergenimMayContain: ''
        })

        this.props.pages.EditElergenimPopUp = false

    }

    UpdateLikesCounter = (number) => {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = this.props.id
        data_to_post.number = number

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/update_likes_counter`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    console.log(res.data)
                });
            })
            .catch(err => { })
    }

    getUniqueListBy = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    @action
    SetValue = () => {
        const ElergenimTitle = this.props.pages.ElergenimTitle
        const ElergenimManufacturer = this.props.pages.ElergenimManufacturer
        const ElergenimContain = this.props.pages.ElergenimContain
        const ElergenimMayContain = this.props.pages.ElergenimMayContain
        const ElergenimDetails = this.props.pages.ElergenimDetails
        const ElergenimCashrot = this.props.pages.ElergenimCashrot

        this.setState({
            ElergenimTitle: ElergenimTitle,
            ElergenimManufacturer: ElergenimManufacturer,
            ElergenimContain: ElergenimContain,
            ElergenimMayContain: ElergenimMayContain,
            ElergenimDetails: ElergenimDetails,
            ElergenimCashrot: ElergenimCashrot
        })
    }

    render() {
        const style = {
            right: this.props.pages.EditElergenimPopUp == true ? '0px' : '-150%',
        }

        const CloseX = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L16 16" stroke="#4B4747" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 2L2 16" stroke="#4B4747" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        const DiningRooms = toJS(this.props.pages.DiningRooms)

        let DiningRoomsList = []


        DiningRooms.map((item, index) => {
            DiningRoomsList.push({ id: item.id, name: item.value })
        })

        DiningRoomsList.reverse()
        let Title = ''
        if (this.props.pages.NewElergenimItem) {
            Title = 'הוספת מידע חדש על אלרגנים'
        }
        if (!this.props.pages.NewElergenimItem) {
            Title = 'עריכת מידע על אלרגנים'
        }


        let SubmitButton = <div onClick={this.state.loading ? null : this.SaveData.bind(this)} className={styles.SaveButton}>שמור</div>
        let DeleteButton = <div onClick={this.state.loading ? null : this.delete_post.bind(this)} className={styles.DeleteButton}>מחיקת מידע</div>

        if (this.props.pages.NewElergenimItem == true) {
            SubmitButton = <div onClick={this.state.loading ? null : this.AddElergenimItem.bind(this)} className={styles.SaveButton}>הוספת מידע</div>
            DeleteButton = null
        }

        let ShowFoodcourtFields = false
        const SelectedDiningRoomsList = toJS(this.props.pages.SelectedDiningRoom)
        const FilterFoodCourt = SelectedDiningRoomsList.filter(item => item.name == 'פודקורט')

        if (FilterFoodCourt.length > 0) {
            ShowFoodcourtFields = true
        }

        const ProgressAnimation = <div className={styles.SpinnerAnimation}><img src={this.props.links.SpinnerAnimation} /></div>

        return (

            <div className={styles.MainContainer} style={style}>
                <div className={styles.FixedBack}></div>
                {this.state.updatind == true ? ProgressAnimation : null}
                <div className={styles.Background}></div>
                <div className={styles.EditItemContainer}>
                    <div className={styles.HideEditContainer}>
                        <div className={styles.HideEdit} onClick={this.CloseEdit.bind(this)}> {CloseX} </div>
                    </div>
                    <div className={styles.Form}>
                        <SectionTitle Title={Title} />
                    </div>
                    <div className={styles.Form}>

                        {/* this.props.pages.ElergenimManufacturer = item.acf.manufacturer
    this.props.pages.ElergenimManufacturerCopy = item.acf.manufacturer

    this.props.pages.ElergenimContain = item.acf.contain
    this.props.pages.ElergenimContainCopy = item.acf.contain

    this.props.pages.ElergenimMayContain = item.acf.may_contain
    this.props.pages.ElergenimMayContainCopy = item.acf.may_contain */}

                        <MuiTextField
                            value={this.state.ElergenimTitle}
                            name={'ElergenimTitle'}
                            placeholder="שם מוצר"
                            helperText="שם מוצר"
                            onChange={this.onChangeTextField.bind(this)}
                            Err={this.state.ElergenimTitleErr}
                            SetValue={this.SetValue.bind(this)}
                        />

                        <MuiTextField
                            value={this.state.ElergenimManufacturer}
                            name={'ElergenimManufacturer'}
                            placeholder="יצרן"
                            helperText="יצרן"
                            Err={this.state.ElergenimManufacturerErr}
                            onChange={this.onChangeTextField.bind(this)}
                            multiline
                        />

                        <MuiTextField
                            value={this.state.ElergenimContain}
                            name={'ElergenimContain'}
                            placeholder="מכיל"
                            helperText="מכיל"
                            Err={this.state.ElergenimContainErr}
                            onChange={this.onChangeTextField.bind(this)}
                            multiline
                        />

                        <MuiTextField
                            value={this.state.ElergenimMayContain}
                            name={'ElergenimMayContain'}
                            placeholder="עלול להכיל"
                            helperText="עלול להכיל"
                            Err={this.state.ElergenimMayContainErr}
                            onChange={this.onChangeTextField.bind(this)}
                            multiline
                        />
                        <MuiTextField
                            value={this.state.ElergenimCashrot}
                            name={'ElergenimCashrot'}
                            placeholder="כשרות"
                            helperText="כשרות"
                            Err={this.state.ElergenimCashrotErr}
                            onChange={this.onChangeTextField.bind(this)}
                            multiline
                        />
                    </div>
                </div>
                {SubmitButton}
                <div className={styles.Err}>{this.state.Err}</div>

                {DeleteButton}
            </div >
        )
    }
}
export default EditItemPopup;