import React from "react";
import styles from './styles/SectionTitle.module.css';

class SectionTitle extends React.Component {
    componentDidMount() {

    }

    render() {
        let Link = null
        if (this.props.LinkTitle) {
            Link = <div onClick={this.props.onClick} className={styles.Link}>{this.props.LinkTitle}</div>
        }
        return (
            <div className={styles.MainContainer} style={{ marginBottom: this.props.MarginBottom,  marginTop: this.props.MarginTop  }}>
                <div className={styles.TitleAndListLink}>
                    <div className={styles.Title}>{this.props.Title}</div>
                    {Link}
                </div>
            </div>
        )
    }
}
export default SectionTitle;