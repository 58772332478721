import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from '@mui/styles'

import Input from "@mui/material/Input";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AiFillEye } from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';

import AccountCircle from '@mui/icons-material/AccountCircle';


class MuiTextFieldPassword extends React.Component {

    constructor() {
        super();
        this.state = {
            showPassword: false,
            password: ''
        }
    }


    componentDidMount() {

    }

    handleClickShowPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    onChangeText = (e) => {
        e.preventDefault
        this.props.onChange(e.target.value)
    }
    render() {

        const primaryColor = '#221b32'
        const secondaryColor = '#27738a'
        const tertiaryColor = '#faa73f'

        const blueSetA = '#27738a'
        const blueSetB = '#1296b0'

        const GreenSetA = '#40835e'
        const GreenSetB = '#83b14d'

        const OrangeSetA = '#faa73f'
        const OrangeSetB = '#ffcc4e'

        const PurpleSetA = '#6c459b'
        const PurpleSetB = '#b072af'

        const navyGreenSetA = '#677f38'
        const navyGreenSetB = '#91a93d'

        const BrownSetA = '#905b24'
        const BrownSetB = '#c27f2a'



        const CssTextField = styled(TextField)({

        })

        return (
            <>

                <TextField
                    margin="normal"
                    fullWidth
                    id='password'
                    placeholder='תעודת זהות'
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.props.password}
                    onChange={this.onChangeText.bind(this)}
                    sx={
                        {
                            fontFamily: `Open Sans, sans-serif`,
                            direction:'ltr',
                            color: '#221b32 !important',
                            backgroundColor:'white',
                            '& .MuiInputBase-input': {
                                color: primaryColor,
                                fontSize: '18px !important',
                                height: '20px !important',
                            },
                            '& label.Mui-focused': {
                                color: 'white',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: 'yellow',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#d5d5d5',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#ababab',
                                },
                                '&.Mui-focused fieldset': {
                                    borderWidth: '1px',
                                    borderColor: '#ababab',
                                    transition: '.2s'
                                },
                            }
                        }
                    }
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword.bind(this)}
                                onMouseDown={this.handleMouseDownPassword.bind(this)}
                                edge="end"
                            >
                                {this.state.showPassword ? <AiFillEyeInvisible color='rgb(39 115 138 / 41%)' /> : <AiFillEye color='rgb(39 115 138 / 41%)' />}
                            </IconButton>
                        )
                    }}
                />
            </>
        )
    }
}
export default MuiTextFieldPassword;