import React from "react";
import styles from './SubCatHeader.module.css';

class SubCatHeader extends React.Component {
    componentDidMount() {

    }

    render() {

        let BackgroundColor = '#49C9D1'
        if (this.props.BackgroundColor) {
            BackgroundColor = this.props.BackgroundColor
        }

        let Title = <>
            <div className={styles.Icon} style={{ width: this.props.IconWidth, bottom: this.props.IconBottom, left: this.props.IconLeft }}>
                <img src={this.props.Icon} />
            </div>
            <div className={styles.Title}>
                <h1>{this.props.Title}</h1>
            </div>
        </>
        if (this.props.Logo) {
            Title = <div className={styles.Logo}>
                <img src={this.props.Logo}></img>
            </div>
        }
        return (
            <div id={this.props.id} className={styles.MainContainer} style={{ marginTop: this.props.MarginTop, backgroundColor: BackgroundColor }}>
                <div className={styles.Container}>
                    {Title}
                </div>
            </div>
        )
    }
}
export default SubCatHeader;