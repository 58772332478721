import React, { Component, Suspense } from 'react';
import Styles from './NotificationsSlider.module.css';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Notification from './Notification';
import SliderDots from './SliderDots';


@inject("login", "links", "pages")
@observer
class NotificationsSlider extends Component {

    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            updateCount: 0
        };
    }

    componentDidMount() {

    }


    SliderItemClick = (e) => {
        console.log('e')
        // this.props.SetPage(e)
    }

    onClickItem = (e) => {
        this.slider.slickGoTo(e)
    }

    render() {
        const loggedIn = this.props.login.loggedIn
        const UserRoles = this.props.login.UserRoles
        let UserType = 'webapp_user'
        if (loggedIn) {
            const isAdmin = UserRoles.includes("webapp_admin")
            if (isAdmin) {
                UserType = 'webapp_admin'
            }
            const webapp_master_admin = UserRoles.includes("webapp_master_admin")
            if (webapp_master_admin) {
                UserType = 'webapp_master_admin'
            }
        }

        const settings = {
            swipeToSlide: true,
            // focusOnSelect: true,
            draggable: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            arrows: false,
            afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
            beforeChange: (current, next) => this.setState({ slideIndex: next })
        };



        const Notifications = toJS(this.props.pages.NotificationsList)

        let NotificationList = null
        let BackColor = 0
        let haveData = false
        if (Notifications.length > 0) {
            NotificationList = Notifications.map((item, index) => {
                let Icon = ''
                let IconWidth = ''
                if (item.acf.show_only_in_dev_mode == true) {
                    if (UserType !== 'webapp_master_admin') {
                        return
                    }
                }
                haveData = true
                switch (item.acf.icon_name) {

                    case 'plate':
                        Icon = this.props.links.IconPlateWhite
                        IconWidth = "100px"
                        break;
                    case 'apron':
                        Icon = this.props.links.IconApronWhite
                        IconWidth = "auto"
                        break;
                    case 'food_cover':
                        Icon = this.props.links.IconFoodCoverWhite
                        IconWidth = "100px"
                        break;
                    case 'hat':
                        Icon = this.props.links.IconHatWhite
                        IconWidth = "auto"
                        break;
                    case 'salting':
                        Icon = this.props.links.IconSaltingWhite
                        IconWidth = "auto"
                        break;
                    case 'silvers':
                        Icon = this.props.links.IconSilversWhite
                        IconWidth = "auto"
                        break;
                    default:
                        Icon = this.props.links.IconPlateWhite
                        IconWidth = "auto"
                }
                BackColor = BackColor + 1
                if (BackColor == 4) {
                    BackColor = 1
                }
                return (

                    <Notification
                        Title={item.acf.text}
                        BackColor={BackColor}
                        index={index}
                        Icon={Icon}
                        IconWidth={IconWidth}
                        UserType={'webapp_user'}
                        onClick={this.SliderItemClick.bind(this, 'aaaa')}
                    />

                )
            })
        }
        let SliderData = null
        if (haveData == true) {
            SliderData = <div className={Styles.SliderContainer}>
                <SliderDots Notifications={Notifications} slideIndex={this.state.slideIndex} onClickItem={this.onClickItem.bind(this)} />
                <Slider
                    ref={slider => (this.slider = slider)}
                    {...settings} className={Styles.Slider}>
                    {NotificationList}
                </Slider>
            </div>
        }
        return (
            <>
                {SliderData}
            </>
        );


    }
}
export default NotificationsSlider;

