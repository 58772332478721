import React from "react";
import styles from './ShowHideButton.module.css';
import { inject, observer } from 'mobx-react';
import axios from "axios";
import { observable, configure, runInAction, action, toJS, makeAutoObservable } from 'mobx';
import clientConfig from "../../client-config";

@inject("login", "links", "pages")
@observer
class ShowHideButton extends React.Component {
    constructor() {
        super();
        this.state = {
            ShowDish: null,
            loading: false,
        };
    }

    componentDidMount() { }

    @action
    add_date_to_dish(PageId, CurrentState) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = PageId
        data_to_post.date = this.props.SelectedDate
        data_to_post.current_state = CurrentState

        this.props.pages.updating = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/add_date_to_dish`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                // this.props.RefreshPage()
                runInAction(() => {

                    const SubCatName = this.props.SubCatName
                    let ArrayToUpdate = toJS(this.props.pages[SubCatName])
                    let SelectedDishIndex = ArrayToUpdate.findIndex(item => item.id == PageId);

                    if (CurrentState == false) {
                        const datesString = ArrayToUpdate[SelectedDishIndex].acf.dates
                        const originalDateString = this.props.SelectedDate //"31/03/2024";
                        let rearrangedDateString = originalDateString
                        var regex = /^\d{2}\/\d{2}\/\d{4}$/;
                        if (regex.test(originalDateString)) {
                            const parts = originalDateString.split('/');
                            rearrangedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];//Output: "2024-03-31"
                        }
                        const newDate = { date_to_show: rearrangedDateString }
                        this.props.pages[SubCatName][SelectedDishIndex].acf.dates = datesString.concat(newDate);
                    }

                    if (CurrentState == true) {
                        const datesString = ArrayToUpdate[SelectedDishIndex].acf.dates //format is "2024-03-31"
                        const originalDateString = this.props.SelectedDate //"31/03/2024";
                        let rearrangedDateString = originalDateString
                        var regex = /^\d{2}\/\d{2}\/\d{4}$/;
                        if (regex.test(originalDateString)) {
                            const parts = originalDateString.split('/');
                            rearrangedDateString = parts[2] + '-' + parts[1] + '-' + parts[0];//Output: "2024-03-31"
                        }
                        const RemoveDateString = datesString.filter(item => item.date_to_show !== rearrangedDateString);
                        this.props.pages[SubCatName][SelectedDishIndex].acf.dates = RemoveDateString;
                    }
                    this.props.pages.updating = false
                });
                this.setState({ ShowDish: null })
            })
            .catch(err => {
                this.props.pages.updating = false
            })
    }



    @action
    show_hide_dish(PageId, CurrentState) {

        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = PageId
        data_to_post.current_state = CurrentState


        this.props.pages.updating = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/show_hide_dish`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    const SubCatName = this.props.SubCatName

                    let ArrayToUpdate = toJS(this.props.pages[SubCatName])
                    let SelectedDishIndex = ArrayToUpdate.findIndex(item => item.id == PageId);


                    if (CurrentState == false) {
                        this.props.pages[SubCatName][SelectedDishIndex].acf.show = true
                    }

                    if (CurrentState == true) {
                        this.props.pages[SubCatName][SelectedDishIndex].acf.show = false
                    }

                    this.props.pages.updating = false

                });
                this.setState({ ShowDish: null })
            })
            .catch(err => {
                this.props.pages.updating = false
            })
    }


    ToggleBt = () => {
        if (this.props.PageType == 'foodcourt' || this.props.PageType == 'gluten_free' || this.props.PageType == 'pasta' || this.props.PageType == 'coffe_shop') {
            this.show_hide_dish(this.props.id, this.props.ShowDish)
            this.setState({ ShowDish: !this.props.ShowDish })
        }
        else {
            this.add_date_to_dish(this.props.id, this.props.ShowDish)
            this.setState({ ShowDish: !this.props.ShowDish })
        }
    }

    render() {
        const buttonStyle = {
            backgroundColor: !this.props.ShowDish ? '#C3C6C9' : "#49C9D1"
        }
        const HandleStyle = {
            borderColor: !this.props.ShowDish ? '#C3C6C9' : "#49C9D1",
            right: !this.props.ShowDish ? '0' : "14px",
        }

        let Content = <div className={styles.MainContainer} style={{ marginTop: this.props.MarginTop }} onClick={this.state.loading ? null : this.ToggleBt.bind(this)}>
            <div className={styles.Container} style={buttonStyle}>
                <div className={styles.BtHandel} style={HandleStyle}></div>
            </div>
        </div>

        if (this.state.ShowDish !== null) {
            const buttonStyleB = {
                backgroundColor: !this.state.ShowDish ? '#C3C6C9' : "#49C9D1"
            }
            const HandleStyleB = {
                borderColor: !this.state.ShowDish ? '#C3C6C9' : "#49C9D1",
                right: !this.state.ShowDish ? '0' : "14px",
            }
            Content = <div className={styles.MainContainer} style={{ marginTop: this.props.MarginTop }} onClick={this.state.loading ? null : this.ToggleBt.bind(this)}>
                <div className={styles.Container} style={buttonStyleB}>
                    <div className={styles.BtHandel} style={HandleStyleB}></div>
                </div>
            </div>
        }

        return (
            <>
                {Content}
            </>
        )
    }
}
export default ShowHideButton;