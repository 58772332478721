import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

class MuiCircularProgress extends React.Component {
    componentDidMount() {

    }


    render() {

        const primaryColor = '#221b32'
        const secondaryColor = '#27738a'
        const tertiaryColor = '#faa73f'

        return (
            <>
                <CircularProgress
                    style={{ position: 'absolute',top:'40vh' }}
                    sx={{
                        color: (theme) => { '#221b32' },
                    }} />
            </>
        )
    }
}
export default MuiCircularProgress;