import React from "react";
import styles from './DiningRoomsItem.module.css';
import Skeleton from '@mui/material/Skeleton';

class DiningRoomsItemLoading extends React.Component {
    componentDidMount() {

    }

    render() {
        let Title = <h1>{this.props.Title}</h1 >
        if (this.props.Title == 'חדר אוכל מהשעה 13:00') {
            Title = <>
                <h1>חדר אוכל</h1 >
                <h2>מהשעה 13:00</h2>
            </>
        }

        return (
            <div className={styles.MainContainer} onClick={this.props.onClick}>
                <div className={styles.Container} style={{ overflow: 'hidden', backgroundColo:'#E3E7E9' }}>
                    <Skeleton variant="circular" width={140} height={140} />
                </div>
            </div>
        )
    }
}
export default DiningRoomsItemLoading;