import { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";
import clientConfig from "../client-config";

const DataContext = createContext()

export default function DataContextProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [selectedRecipesCategory, setSelectedRecipesCategory] = useState('');
    const [selectedPage, setSelectedPage] = useState('');
    const [recipesData, setRecipesData] = useState('');
    

    return (
        <DataContext.Provider
            value={{
                loading,
                setLoading,
                selectedRecipesCategory,
                setSelectedRecipesCategory,
                recipesData,
                setRecipesData
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export function useDataContext() {
    return useContext(DataContext);  // Hook to access context
}