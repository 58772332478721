import React, { Component } from 'react';
import styles from './styles/EnterButton.module.css';

class EnterButton extends Component {
    constructor() {
        super();
        this.state = {}
    }
    render() {
        return (
            <>
                <div  className={styles.EnterButtonConatiner} style={this.props.style} onClick={this.props.onClick}>
                    <div className={styles.EnterButton} >
                        <h1>
                            כניסה
                        </h1>
                    </div>
                </div>

            </>
        );
    }
}
export default EnterButton;