import React from "react";
import TextField from "@mui/material/TextField";

import Input from "@mui/material/Input";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AiFillEye } from 'react-icons/ai';
import { AiFillEyeInvisible } from 'react-icons/ai';

import AccountCircle from '@mui/icons-material/AccountCircle';


class MuiTextField extends React.Component {

    constructor() {
        super();
        this.state = {
            text: ''
        }
    }


    componentDidMount() {

    }

    onChangeText = (e) => {
        e.preventDefault
        this.props.onChange(e)
    }

    render() {

        const primaryColor = '#221b32'
        const secondaryColor = '#27738a'
        const tertiaryColor = '#faa73f'

        const blueSetA = '#27738a'
        const blueSetB = '#1296b0'

        const GreenSetA = '#40835e'
        const GreenSetB = '#83b14d'

        const OrangeSetA = '#faa73f'
        const OrangeSetB = '#ffcc4e'

        const PurpleSetA = '#6c459b'
        const PurpleSetB = '#b072af'

        const navyGreenSetA = '#677f38'
        const navyGreenSetB = '#91a93d'

        const BrownSetA = '#905b24'
        const BrownSetB = '#c27f2a'

        let opacity = '1'
        if (this.props.value == '') {
            opacity = '0'
        }

        let Erropacity = '0'
        if (this.props.Err !== '') {
            Erropacity = '1'
        }

        let FieldDescriptionStyle = { fontSize: '11px', color: '#979797', transition: '.2s', opacity: opacity }
        let ErrStyle = { fontSize: '11px', color: 'red', transition: '.2s', opacity: Erropacity }


        return (
            <>
                <TextField
                    margin="normal"
                    fullWidth
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    onChange={this.onChangeText.bind(this)}
                    value={this.props.value}
                    name={this.props.name}
                    multiline={this.props.multiline}
                    helperText={this.props.value == '' ? '' : this.props.helperText}
                    // // label="User Name"
                    // autoComplete="email"
                    // // autoFocus
                    sx={{
                        color: '#221b32 !important',
                        '& .MuiFormHelperText-root': {
                            position: 'absolute',
                            bottom: '-20px',
                            right: '0px',
                            fontFamily: "Heebo",
                            color: '#c9c9c9',
                        },
                        '& .MuiInputBase-input': {
                            fontFamily: `Heebo`,
                            fontWeight: '500',
                            color: primaryColor,
                            fontSize: '18px !important',
                            height: '20px !importan',
                            padding: '5px'
                        },
                        '& label.Mui-focused': {
                            color: 'white',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: 'yellow',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            // borderBottom: '1px solid #212529',
                            borderBottomColor: '#a7a7a7',
                            borderBottomWidth: '1px',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderTop: 'none',
                            borderRadius: '0px'
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#a7a7a7',
                            },
                            '&:hover fieldset': {
                                borderColor: '#a7a7a7',
                            },
                            '&.Mui-focused fieldset': {
                                borderWidth: '1px',
                                borderBottomColor: '#a7a7a7',
                                borderRadius: '0px'
                            }
                        },
                    }}
                />
                {/* {this.props.value == '' ? } */}
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: this.props.marginBottom == '' ? 'inherit' : this.props.marginBottom }}>

                    <div style={{ position: 'absolute', opacity: '0', zIndex: '-5000' }} onClick={() => this.props.SetValue()} id={`set_${this.props.name}`}>a</div>

                    <div style={FieldDescriptionStyle}>{this.props.FieldDescription}</div>
                    <div style={ErrStyle}>{this.props.Err}</div>
                </div>
            </>
        )
    }
}
export default MuiTextField;