import React from "react";
import styles from './EditItemPopup.module.css';
import Cookies from 'universal-cookie';
import { inject, observer } from 'mobx-react';
import clientConfig from "../../client-config";
import axios from "axios";
import { observable, configure, runInAction, action, toJS, makeAutoObservable } from 'mobx';
import SectionTitle from "../SectionTitle";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import MuiTextField from "../MuiAssets/MuiTextField"
import MuiCircularProgress from "../MuiAssets/MuiCircularProgress"
import DateRangePicker from '../MuiAssets/DateRangePicker'
import TimePicMuiTimePickerer from "../MuiAssets/MuiTimePicker";
import MuiTimePicker from "../MuiAssets/MuiTimePicker";
import Moment from 'react-moment';
import moment from 'moment';
import parse from 'html-react-parser';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';



@inject("login", "links", "pages")
@observer
class EditInfoPopup extends React.Component {
    constructor() {
        super();
        this.state = {
            InfoTitle: '',
            InfoDetails: '',
            InfoTitleErr: '',
            InfoDetailsErr: '',
        };
    }

    componentDidMount() {

    }

    AddInfoItem = () => {
        let Err = false
        const InfoTitle = this.state.InfoTitle
        const InfoDetails = this.state.InfoDetails
        if (InfoTitle == '') {
            this.setState({
                InfoTitleErr: 'נא למלא כותרת'
            })
            Err = true
        }
        if (InfoDetails == '') {
            this.setState({
                InfoDetailsErr: 'נא למלא מידע'
            })
            Err = true
        }
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.info_title = InfoTitle
        data_to_post.info_details = InfoDetails
        if (Err == false) {
            this.create_info_page(data_to_post)
        }
    }


    @action
    create_info_page(data_to_post) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/create_info_page`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    this.props.pages.updating = false
                    this.setState({ updatind: false })
                    this.CloseEdit()
                    if (document.getElementById('RefreshPage')) {
                        document.getElementById('RefreshPage').click()
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }


    @action
    onChangeTextField = (e) => {
        const Name = e.target.name
        const Value = e.target.value
        this.props.pages[e.target.name] = e.target.value
        this.setState({
            [Name]: Value,
            [`${Name}Err`]: '',
        })
    }

    SaveData = () => {
        let Err = false
        const InfoTitleCopy = this.props.pages.InfoTitleCopy
        const InfoTitle = this.state.InfoTitle
        const InfoTitleNeedUpdate = InfoTitle !== InfoTitleCopy
        if (InfoTitle == '') {
            this.setState({
                InfoTitleErr: 'נא למלא כותרת'
            })
            Err = true
        }

        const InfoDetailsCopy = this.props.pages.InfoDetailsInfoDetailsCopy
        const InfoDetails = this.state.InfoDetails
        const InfoDetailsNeedUpdate = InfoDetails !== InfoDetailsCopy
        if (InfoDetails == '') {
            this.setState({
                InfoDetailsErr: 'נא למלא מידע'
            })
            Err = true
        }
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = this.props.pages.SelectedInfoPageId
        data_to_post.info_title = InfoTitle
        data_to_post.info_title_need_update = InfoTitleNeedUpdate
        data_to_post.info_details = InfoDetails
        data_to_post.info_details_need_update = InfoDetailsNeedUpdate

        if (Err == false) {
            this.update_info_page(data_to_post)
        }
    }


    @action
    update_info_page(data_to_post) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/update_info_page`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.props.pages.updating = false
                    this.setState({ updatind: false })
                    this.CloseEdit()
                    if (document.getElementById('RefreshPage')) {
                        document.getElementById('RefreshPage').click()
                    }
                });
            })
            .catch(err => {
            })
    }

    @action
    delete_post() {
        const SelectedItemId = this.props.pages.SelectedInfoPageId
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = SelectedItemId

        const wordPressSiteUrl = clientConfig.siteUrl;

        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/delete_post`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    this.props.pages.updating = false
                    this.CloseEdit()
                    if (document.getElementById('RefreshPage')) {
                        document.getElementById('RefreshPage').click()
                    }
                });
            })
            .catch(err => {

            })
    }







    @action
    CloseEdit = () => {
        window.scrollTo({ top: -this.props.pages.CurrentScrollPosition, left: 0, behavior: "instant" })
        this.props.pages.InfoTitle = ''
        this.props.pages.InfoDetails = ''
        this.props.pages.SelectedInfoPageId = ''
        this.props.pages.EditInfoPopUp = false
        this.props.pages.EditInfoPopUp = false
        this.props.pages.updating = false
       
        this.setState({
            InfoTitle: '',
            InfoDetails: '',
            updatind: false
        })
    }

    UpdateLikesCounter = (number) => {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = this.props.id
        data_to_post.number = number

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/update_likes_counter`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    console.log(res.data)
                });
            })
            .catch(err => { })
    }

    getUniqueListBy = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    @action
    SetValue = () => {
        const InfoTitle = this.props.pages.InfoTitle
        const InfoDetails = this.props.pages.InfoDetails
        this.setState({
            InfoTitle: InfoTitle,
            InfoDetails: InfoDetails
        })
    }


    render() {
        const style = {
            right: this.props.pages.EditInfoPopUp == true ? '0px' : '-150%',
        }

        const CloseX = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L16 16" stroke="#4B4747" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 2L2 16" stroke="#4B4747" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        const DiningRooms = toJS(this.props.pages.DiningRooms)

        let DiningRoomsList = []


        DiningRooms.map((item, index) => {
            DiningRoomsList.push({ id: item.id, name: item.value })
        })

        DiningRoomsList.reverse()
        let Title = ''
        if (this.props.pages.NewInfoItem) {
            Title = 'הוספת מידע לעובד'
        }
        if (!this.props.pages.NewInfoItem) {
            Title = 'עריכת מידע לעובד'
        }


        let SubmitButton = <div onClick={this.state.loading ? null : this.SaveData.bind(this)} className={styles.SaveButton}>שמור</div>
        let DeleteButton = <div onClick={this.state.loading ? null : this.delete_post.bind(this)} className={styles.DeleteButton}>מחיקת מידע</div>

        if (this.props.pages.NewInfoItem == true) {
            SubmitButton = <div onClick={this.state.loading ? null : this.AddInfoItem.bind(this)} className={styles.SaveButton}>הוספת מידע</div>
            DeleteButton = null
        }

        let ShowFoodcourtFields = false
        const SelectedDiningRoomsList = toJS(this.props.pages.SelectedDiningRoom)
        const FilterFoodCourt = SelectedDiningRoomsList.filter(item => item.name == 'פודקורט')

        if (FilterFoodCourt.length > 0) {
            ShowFoodcourtFields = true
        }

        const ProgressAnimation = <div className={styles.SpinnerAnimation}><img src={this.props.links.SpinnerAnimation} /></div>

        return (

            <div className={styles.MainContainer} style={style}>
                <div className={styles.FixedBack}></div>
                {this.state.updatind == true ? ProgressAnimation : null}
                <div className={styles.Background}></div>
                <div className={styles.EditItemContainer}>
                    <div className={styles.HideEditContainer}>
                        <div className={styles.HideEdit} onClick={this.CloseEdit.bind(this)}> {CloseX} </div>
                    </div>
                    <div className={styles.Form}>

                        <SectionTitle Title={Title} />



                    </div>
                    <div className={styles.Form}>
                        <MuiTextField
                            value={this.state.InfoTitle}
                            name={'InfoTitle'}
                            placeholder="כותרת"
                            onChange={this.onChangeTextField.bind(this)}
                            Err={this.state.InfoTitleErr}
                            SetValue={this.SetValue.bind(this)}
                        />

                        <MuiTextField
                            value={this.state.InfoDetails}
                            name={'InfoDetails'}
                            placeholder="מידע"
                            Err={this.state.InfoDetailsErr}
                            onChange={this.onChangeTextField.bind(this)}
                            multiline
                        />

                    </div>
                </div>
                {SubmitButton}
                <div className={styles.Err}>{this.state.Err}</div>

                {DeleteButton}
            </div >
        )
    }
}
export default EditInfoPopup;