import React, { Component, Suspense } from 'react';
import styles from './styles/DiningRoom.module.css';
import { inject, observer } from 'mobx-react';
import { toJS, runInAction } from 'mobx';
import DaysSlider from '../components/DaysSlider/DaysSlider';
import SectionTitle from '../components/SectionTitle';
import moment from 'moment';
import LoginCheck from '../components/LoginCheck';
import SubCatHeader from '../components/SubCats/SubCatHeader';
import SubCatItem from '../components/SubCats/SubCatItem'
import clientConfig from '../client-config';
import axios from 'axios';
import parse from 'html-react-parser';
import CreateNewInfo from '../components/CreateNewInfo'
import MuiAccordion from '../components/MuiAssets/MuiAccordion';

@inject("login", "links", "pages")
@observer
class GlotenFree extends Component {

    constructor() {
        super();
        this.state = {
            Filtering: true,
            ItemsList: [],
        };
    }

    componentDidMount() {
        this.GetData()
    }

    EditItemPopUp = (Title, Details, id) => {
        this.props.pages.NewInfoItem = false
        this.props.pages.CurrentScrollPosition = document.body.getBoundingClientRect().top
        window.scrollTo({ top: 0, left: 0, behavior: "instant" })
        this.props.pages.InfoTitle = Title
        this.props.pages.InfoTitleCopy = Title
        this.props.pages.InfoDetails = Details
        this.props.pages.InfoDetailsCopy = Details
        this.props.pages.SelectedInfoPageId = id
        if (document.getElementById('set_InfoTitle')) {
            document.getElementById('set_InfoTitle').click()
        }

        this.props.pages.EditInfoPopUp = true
    }

    RefreshPage = () => {
        this.props.pages.updating = true
        this.GetData()
    }


    GetData() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const PerPage = 100
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/info/?_embed&acf_format=standard&per_page=100`)
            .then(res => {
                runInAction(() => {
                    this.setState({
                        Filtering: false,
                        ItemsList: res.data.reverse()
                    })
                });
            })
            .catch(err => {
                console.log('LoadPage ERR: ' + err)
            })
    };


    GetDishes(offset) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const PerPage = 100
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/dishes?_embed&acf_format=standard&per_page=${PerPage}&offset=${offset}`)
            .then(res => {
                runInAction(() => {
                    const Arr1 = this.state.Dishes
                    const Arr2 = res.data
                    const Arr3 = Arr1.concat(Arr2);
                    let RemoveDuplicates = [...new Set(Arr3)];
                    if (res.data.length == PerPage) {
                        const newOffset = offset + PerPage
                        this.setState({ Dishes: Arr3 })
                        this.GetDishes(newOffset)
                        return
                    }
                    this.FilterAsync(RemoveDuplicates)
                });
            })
            .catch(err => {
                console.log('LoadPage ERR: ' + err)
            })
    };

    // סינון המנות משתנה לפי הקטגוריה
    FilterAsync = async (Arr3) => {
        // console.log(Arr3)
        this.setState({
            Filtering: true
        })
        this.props.pages.updating = true

        let VegetablesItems = []
        let SaucesItems = []

        const promises = Arr3.map((item, index) => {

            if (item.DiningRoom.includes(this.props.pages.SlectedCatId)) {
                if (item.acf.dish_type_salad.includes('ירקות')) {
                    VegetablesItems.push(item)
                }
                if (item.acf.dish_type_salad.includes('רטבים')) {
                    SaucesItems.push(item)
                }
            }
        })
        await Promise.all(promises).then((values) => {
            this.setState({
                Filtering: false,
            })
            this.props.pages.VegetablesItems = VegetablesItems.reverse()
            this.props.pages.SaucesItems = SaucesItems.reverse()
            this.props.pages.updating = false
        });
    }

    //MAP Async END



    render() {
        const loggedIn = this.props.login.loggedIn
        const UserRoles = this.props.login.UserRoles
        let UserType = 'webapp_user'
        if (loggedIn) {
            const isAdmin = UserRoles.includes("webapp_admin")
            if (isAdmin) {
                UserType = 'webapp_admin'
            }
        }


        let pageContent = <div
            style={{ backgroundImage: `url(${this.props.links.FoodPattern})` }}
            className={styles.LoadingMainContainer} ><img src={this.props.links.DinningRoomGif} /></div>


        if (this.state.Filtering == false) {
            let Items = null
            let colorByIndex = 0
            if (this.state.ItemsList.length > 0) {
                Items = this.state.ItemsList.map((item, index) => {
                    let color = ""

                    if (colorByIndex == 0) {
                        color = "#49C9D1"
                    }

                    if (colorByIndex == 1) {
                        color = "#C5A0FA"
                    }

                    if (colorByIndex == 2) {
                        color = "#F5C037"
                    }

                    colorByIndex = colorByIndex + 1
                    if (colorByIndex == 3) {
                        colorByIndex = 0
                    }

                    return (
                        <MuiAccordion
                            backgroundColor={color}
                            Title={item.title.rendered}
                            Details={item.acf.details}
                            id={item.id}
                            UserType={UserType}
                            EditItemPopUp={this.EditItemPopUp.bind(this, item.title.rendered, item.acf.details, item.id)}
                        />
                    )
                })
            }
            pageContent =
                <>
                    <CreateNewInfo UserType={UserType} />
                    <div className={styles.MainContainer} style={{ paddingTop: this.props.PaddingTop }}>
                        <div id='RefreshPage' style={{ display: 'none', position: 'absolute', opacity: '0', zIndex: '-2550' }} onClick={this.RefreshPage.bind(this, 0)}></div>
                        <SectionTitle Title="מידע לעובד" MarginBottom="15px" MarginTop="-15px" />
                        {Items}
                    </div>
                </>
        }

        return (
            <LoginCheck loggedin={true}>

                {pageContent}
            </LoginCheck>
        );
    }
}
export default GlotenFree;

