import React from "react";
import styles from './DaysSlider.module.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { inject, observer } from 'mobx-react';
import DaysSliderItem from "./DaysSliderItem";
import Moment from 'react-moment';

import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';


const ArrowLeft = <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 1L1 5L5 9" stroke="#4B4747" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>


const ArrowRight = <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9L5 5L1 1" stroke="#4B4747" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>

@inject("login", "links", "pages")
@observer
class DaysSlider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            SliderLastIndex: null,
            FirstClick: true,
            days: [],
        }
    }

    componentDidMount() {
        this.FilterdAsync()

    }

    componentDidUpdate() {
        if (this.state.SliderLastIndex && this.state.FirstClick) {

            const url = new URL(window.location);
            const UrlId = url.searchParams.get('id');
            if (UrlId !== '') {
                if (document.getElementById(`${UrlId}`)) {
                    document.getElementById(`${UrlId}`).click()
                }
                this.setState({ FirstClick: false })
            }
            if (UrlId == null) {
                if (document.getElementById(`day${this.state.SliderLastIndex - 2}`)) {
                    document.getElementById(`day${this.state.SliderLastIndex - 2}`).click()
                    this.setState({ FirstClick: false })
                }
            }
        }
    }

    DayClicked = (index, day) => {
        this.slider.slickGoTo(index - 1)
        this.props.DayClicked(day, `day${index}`)
    }

    beforeChange = (current, next) => {
        if (document.getElementById(`day${next + 1}`)) {
            document.getElementById(`day${next + 1}`).click()
        }
    }

    isBefore = (date1, date2) => {
        return date1 < date2;
    }

    //MAP Async START
    FilterdAsync = async () => {

        const today = new Date();


        let DayToStart = new Date(today);

        const day = today.getDay()
        // console.log(DayToStart)
        // if not sunday Start From Sunday
        // if (day !== 0) {
        //     DayToStart.setDate(today.getDate() - Number(day))
        // }
        // if not sunday Start From Sunday

        DayToStart.setDate(today.getDate() - 2)

        let Days = []
        for (let i = 0; i < 30; i++) {
            let NextDay = new Date(DayToStart);
            NextDay.setDate(DayToStart.getDate() + i)
            Days.push(NextDay)
        }
        let SlideIndex = 0
        const reversed = Days.reverse();
        const promises = reversed.map((item, index) => {

            const day = new Date(item);
            const isBefore = this.isBefore(day, today)
            const Month = day.getMonth() + 1
            const DD_Date = day.getDate()
            const dayofweek = day.getDay()

            let DayHeb = ''

            const MonthAdd0 = (day.getMonth() + 1).toString().padStart(2, "0"); // Add 0 to one number
            const DateAdd0 = (day.getDate()).toString().padStart(2, "0"); // Add 0 to one number

            const DateString = `${DateAdd0}/${MonthAdd0}/${day.getFullYear()}`



            if (dayofweek == 0) {
                DayHeb = `יום א'`
                SlideIndex = SlideIndex + 1
            }
            if (dayofweek == 1) {
                DayHeb = `יום ב'`
                SlideIndex = SlideIndex + 1
            }
            if (dayofweek == 2) {
                DayHeb = `יום ג'`
                SlideIndex = SlideIndex + 1
            }
            if (dayofweek == 3) {
                DayHeb = `יום ד'`
                SlideIndex = SlideIndex + 1
            }
            if (dayofweek == 4) {
                DayHeb = `יום ה'`
                SlideIndex = SlideIndex + 1
            }
            if (dayofweek == 5) {
                DayHeb = `יום ו'`
                SlideIndex = SlideIndex + 1
            }
            if (dayofweek == 6) {
                DayHeb = `יום ש'`
                SlideIndex = SlideIndex + 1
            }

            return (
                <>
                    <DaysSliderItem
                        isBefore={isBefore}
                        id={`day${SlideIndex}`}
                        onClick={this.DayClicked.bind(this, SlideIndex, DateString)}
                        Date={`${DayHeb}  ${DD_Date}/${Month}`}
                    />
                </>
            )
        })

        await Promise.all(promises).then((values) => {
            this.setState({
                days: promises,
                SliderLastIndex: SlideIndex
            })

        });

    }
    //MAP Async END

    render() {
        const NextArrow = (props) => {
            const { className, style, onClick } = props;
            return (
                <div
                    className={className + ' TabsArrowRight'}
                    onClick={onClick}
                >
                    {ArrowRight}
                </div>
            );
        }

        const PrevArrow = (props) => {
            const { className, style, onClick } = props;
            return (
                <div
                    className={className + ' TabsArrowLeft'}
                    onClick={onClick}
                >
                    {ArrowLeft}
                </div>
            );
        }

        const settings = {
            swipeToSlide: true,
            // rtl: true,
            // currentSlide: 0,
            infinite: false,
            dots: false,
            arrows: true,
            centerMode: true,
            centerPadding: "0px",
            slidesToShow: 3,
            slidesToScroll: 1,
            Infinity: false,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            beforeChange: this.beforeChange.bind(this)
        };


        const LinearProgressStyle = { opacity: this.props.updating ? 1 : 0 }
        return (
            <div className={styles.MainContainer + ' DaysSliderMainContainer'}>

                <Stack sx={{ color: '#49C9D1' }} className={styles.LinearProgress} style={LinearProgressStyle}>
                    <LinearProgress color="inherit" />
                </Stack>

                <div className={styles.FlexContainer}>
                    <div className={styles.Container}>
                        <Slider
                            ref={slider => (this.slider = slider)}
                            {...settings} className={styles.Slider}>
                            {this.state.days}
                        </Slider>
                    </div>
                </div>

            </div>
        )
    }
}
export default DaysSlider;