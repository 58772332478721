import React from "react";
import styles from './DiningRoomsListItem.module.css';

class DiningRoomsListItem extends React.Component {
    componentDidMount() {

    }

    render() {
        const style = { width: this.props.IconWidth, bottom: this.props.IconBottom, left: this.props.IconLeft }

        let Title = <h1>{this.props.Title}</h1 >
        if (this.props.Title == 'הדרן') {
            Title = <div className={styles.Title}>
                <h1>הדרן</h1 >
                <h2>מ-13:15</h2>
            </div>
        }
        if (this.props.Title == 'סטקייה') {
            Title = <>
                <h1>הסטקיה</h1>
            </>
        }
        if (this.props.Title == 'אלרגנים') {
            Title = <>
                <h1>מעבר ל-kitchen</h1>
            </>
        }

       


        return (
            <div className={styles.MainContainer} onClick={this.props.onClick}>
                <div className={styles.Container}>
                    <div className={styles.Icon} style={style}>{this.props.Icon}</div>
                    {Title}
                </div>
            </div>
        )
    }
}
export default DiningRoomsListItem;