import React, { Component, Suspense } from 'react';
import styles from './styles/Notifications.module.css';
import { inject, observer } from 'mobx-react';
import LoginCheck from '../components/LoginCheck';
import { runInAction, toJS } from 'mobx';
import SectionTitle from '../components/SectionTitle';
import Notification from '../components/Notifications/Notification';
import CreateNewNotification from '../components/CreateNewNotification'
import { List, arrayMove } from 'react-movable';
import axios from 'axios';
import clientConfig from '../client-config';
import moment from 'moment';

@inject("login", "links", "pages")
@observer
class Notifications extends Component {

    constructor() {
        super();
        this.state = {
            Notifications: [],
            loading: false
        };
    }


    componentDidMount() {
        this.GetNotifications()
    }

    GetNotifications() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        // this.setState({
        //     loading: true
        // })
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/notifications/?skip_cache=1&acf_format=standard`)
            .then(res => {
                const Notifications = res.data
                Notifications.sort((a, b) => (parseInt(a.acf.order) > parseInt(b.acf.order)) ? 1 : -1)

                runInAction(() => {

                    let List = []
                    const today = new Date();
                    res.data.map((item, index) => {

                        if (item.acf.start_date == '') {
                            List.push(item)
                        }
                        if (item.acf.start_date !== '') {

                            const startDateMomentObject = moment(item.acf.start_date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
                            let startDateObject = startDateMomentObject.toDate(); // convert moment.js object to Date object
                            const startTime = item.acf.start_time

                            if (startTime !== '') {
                                const startTimeSlpit = startTime.split(':');
                                startDateObject.setHours(startTimeSlpit[0], startTimeSlpit[1])
                            }

                            if (startDateObject <= today) {
                                List.push(item)
                            }
                        }
                        if (item.acf.end_date !== '') {

                            const endDateMomentObject = moment(item.acf.end_date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
                            let endDateObject = endDateMomentObject.toDate(); // convert moment.js object to Date object
                            const EndTime = item.acf.end_time
                            if (EndTime !== '') {
                                const EndTimeSlpit = EndTime.split(':');
                                endDateObject.setHours(EndTimeSlpit[0], EndTimeSlpit[1])
                            }

                            if (endDateObject <= today) {
                                List = List.filter(Listitem => Listitem.id !== item.id);
                                console.log(item.id)
                                this.delete_post(item.id)
                            }
                        }

                        const RemoveDuplicates = [...new Set(List)];
                        List = RemoveDuplicates
                        return null
                    })
                    this.setState({
                        Notifications: List,
                        loading: false
                    })

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.setState({
                        loading: false
                    })
                    console.log('NotificationList ERR: ' + err)
                });
            })
    };


    OrderItems = (Notifications, oldIndex, newIndex) => {
        let items = [...Notifications]
        items[oldIndex].acf.order = newIndex + 1
        const NewArr = arrayMove(Notifications, oldIndex, newIndex)
        this.setState({
            Notifications: NewArr
        })
        let NotificationsIds = []
        NewArr.map((item, index) => {
            NotificationsIds.push(item.id)
        })
        this.SetNotificationsOrder(NotificationsIds)

    }


    SetNotificationsOrder = (NotificationsIds) => {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.NotificationsIds = NotificationsIds
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/set_notifications_order`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                runInAction(() => {
                    this.GetNotifications()
                    this.props.pages.GetNotifications()
                });
            })
            .catch(err => {
                console.log('set_notifications_order ERR: ' + err)
            })
    };



    render() {
        const loggedIn = this.props.login.loggedIn
        const UserRoles = this.props.login.UserRoles
        let UserType = 'webapp_user'
        if (loggedIn) {

            const isAdmin = UserRoles.includes("webapp_admin")
            if (isAdmin) {
                UserType = 'webapp_admin'
            }
            const webapp_master_admin = UserRoles.includes("webapp_master_admin")
            if (webapp_master_admin) {
                UserType = 'webapp_master_admin'
            }
        }

        const Notifications = this.state.Notifications
        let NotificationList = null
        let BackColor = 0

        let ListType = null
        const Loading = this.state.loading

        if (!Loading) {
            if (this.state.Notifications.length > 0) {
                NotificationList = Notifications.map((item, index) => {
                    let Icon = ''
                    let IconWidth = ''
                    if (item.acf.show_only_in_dev_mode == true) {
                        if (UserType !== 'webapp_master_admin') {
                            return
                        }
                    }
                    switch (item.acf.icon_name) {
                        case 'plate':
                            Icon = this.props.links.IconPlateWhite
                            IconWidth = "100px"
                            break;
                        case 'apron':
                            Icon = this.props.links.IconApronWhite
                            IconWidth = "auto"
                            break;
                        case 'food_cover':
                            Icon = this.props.links.IconFoodCoverWhite
                            IconWidth = "100px"
                            break;
                        case 'hat':
                            Icon = this.props.links.IconHatWhite
                            IconWidth = "auto"
                            break;
                        case 'salting':
                            Icon = this.props.links.IconSaltingWhite
                            IconWidth = "auto"
                            break;
                        case 'silvers':
                            Icon = this.props.links.IconSilversWhite
                            IconWidth = "auto"
                            break;
                        default:
                            Icon = this.props.links.IconPlateWhite
                            IconWidth = "auto"
                    }
                    BackColor = BackColor + 1
                    if (BackColor == 4) {
                        BackColor = 1
                    }
                    return (
                        <div className={styles.NotificationItem}>
                            <Notification
                                Title={item.acf.text}
                                BackColor={BackColor}
                                index={index}
                                Icon={Icon}
                                IconWidth={IconWidth}
                                id={item.id}
                                UserType={UserType}

                            />
                        </div>
                    )
                })
            }

            const ListItems = <List
                values={this.state.Notifications}
                onChange={({ oldIndex, newIndex }) =>
                    this.OrderItems(Notifications, oldIndex, newIndex)
                }

                renderList={({ children, props }) => {
                    return (
                        <div {...props}>{children}</div>
                    )
                }
                }
                renderItem={({ value, props, index, isDragged, isSelected }) => {

                    let Icon = ''
                    let IconWidth = ''
                    if (value.acf.show_only_in_dev_mode == true) {
                        if (UserType !== 'webapp_master_admin') {
                            return
                        }
                    }
                    switch (value.acf.icon_name) {
                        case 'plate':
                            Icon = this.props.links.IconPlateWhite
                            IconWidth = "100px"
                            break;
                        case 'apron':
                            Icon = this.props.links.IconApronWhite
                            IconWidth = "auto"
                            break;
                        case 'food_cover':
                            Icon = this.props.links.IconFoodCoverWhite
                            IconWidth = "100px"
                            break;
                        case 'hat':
                            Icon = this.props.links.IconHatWhite
                            IconWidth = "auto"
                            break;
                        case 'salting':
                            Icon = this.props.links.IconSaltingWhite
                            IconWidth = "auto"
                            break;
                        case 'silvers':
                            Icon = this.props.links.IconSilversWhite
                            IconWidth = "auto"
                            break;
                        default:
                            Icon = this.props.links.IconPlateWhite
                            IconWidth = "auto"
                    }
                    BackColor = BackColor + 1
                    if (BackColor == 4) {
                        BackColor = 1
                    }

                    return (

                        <div className={styles.NotificationItem}{...props}>
                            <Notification
                                Title={value.acf.text}
                                BackColor={BackColor}
                                index={props.key}
                                Icon={Icon}
                                IconName={value.acf.icon_name}
                                IconWidth={IconWidth}
                                id={value.id}
                                UserType={UserType}
                                startDate={value.acf.start_date}
                                startTime={value.acf.start_time}
                                endDate={value.acf.end_date}
                                endTime={value.acf.end_time}
                            />


                        </div>


                    )
                }}
            />

            ListType = <div className={styles.MainContainer} style={{ paddingTop: this.props.PaddingTop }}>
                <SectionTitle Title="התראות" MarginBottom="15px" MarginTop="-15px" />
                {NotificationList}
            </div>

            if (UserType == "webapp_admin" || UserType == "webapp_master_admin") {
                ListType = <div className={styles.MainContainer} style={{ paddingTop: this.props.PaddingTop }}>
                    {ListItems}
                </div>
            }
        }
        return (
            <LoginCheck loggedin={true}>
                <div id="get_notifications" onClick={this.GetNotifications.bind(this)}></div>
                <CreateNewNotification UserType={UserType} />
                {ListType}
                <div style={{paddingBottom:'50px'}}></div>
            </LoginCheck>
        );


    }
}
export default Notifications;

