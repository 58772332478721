import React from "react";
import styles from './styles/MenuItem.module.css';

class MenuItem extends React.Component {
    componentDidMount() {

    }

    render() {
        let MarginTop = '40px'
        if (this.props.MarginTop) {
            MarginTop = this.props.MarginTop
        }
        return (
            <div className={styles.MenuItem} style={{ marginTop: MarginTop }} onClick={this.props.onClick}>
                <div className={styles.Icon}><img src={this.props.Icon} style={{ width: this.props.IconWidth }} /></div>
                <div className={styles.Text}>{this.props.Text}</div>
            </div>
        )
    }
}
export default MenuItem;