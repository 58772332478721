import React from "react";
import styles from './EditNotificationPopup.module.css';
import Cookies from 'universal-cookie';
import { inject, observer } from 'mobx-react';
import clientConfig from "../../client-config";
import axios from "axios";
import { observable, configure, runInAction, action, toJS, makeAutoObservable } from 'mobx';
import SectionTitle from "../SectionTitle";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import MuiTextField from "../MuiAssets/MuiTextField"
import MuiCircularProgress from "../MuiAssets/MuiCircularProgress"
import DateRangePicker from '../MuiAssets/DateRangePicker'
import TimePicMuiTimePickerer from "../MuiAssets/MuiTimePicker";
import MuiTimePicker from "../MuiAssets/MuiTimePicker";
import Moment from 'react-moment';
import moment from 'moment';
import parse from 'html-react-parser';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';


const CustomAlertIcon = (props) => {
    let Icon = props.Icon
    let hoverStyle = styles.AlertIconHover
    let CustomAlertIconstyle = {
        backgroundColor: 'white'
    }
    if (props.Selected) {
        Icon = props.IconWhite
        CustomAlertIconstyle = { backgroundColor: '#49C9D1' }
        hoverStyle = ''
    }
    return (
        <div className={styles.AlertIcon + ' ' + hoverStyle} style={CustomAlertIconstyle} onClick={props.OnClick}>
            <img src={Icon} style={{ width: props.width }} />
        </div>
    )
}

@inject("login", "links", "pages")
@observer
class EditNotificationPopup extends React.Component {
    constructor() {
        super();
        this.state = {
            CustomAlertTitle: '',
            CustomAlertStartData: '',
            CustomAlertEndData: '',
            CustomAlertStartTime: '',
            CustomAlertEndTime: '',
            CustomAlertIcon: '', // Icons Options: plate, apron, food_cover, hat, salting, silvers
            updatind: false,
            disableCategoriesField: false,
            SelectedDiningRoom: [],
            SelectedFoodcourtCategory: '',
            SelectedCategory: [],
            SelectedDishName: [],
            SelectedDishIngedients: '',
            SelectedDishComment: '',
            SelectedDishGlotenFree: false,
            CreateAlert: true,
            GlotenFree: false,

            ShowDatePicker: false,
            ShowTimePicker: false,
            Err: '',
            DiningRoomsListErr: '',
            CategoryErr: '',
            FoodCourtCategoryErr: '',
            SelectedDishNameErr: '',
            DatesToFromArr: '',
            ExistingItem: false
        };
    }

    componentDidMount() {

    }

    convertStringToDate = (dateStr) => {
        const [day, month, year] = dateStr.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        return date;
    };

    SetItems = () => {
        const StartDate = this.convertStringToDate(this.props.pages.CustomAlertStartDate)
        const EndDate = this.convertStringToDate(this.props.pages.CustomAlertEndDate)
        // const StartTime = this.props.pages.CustomAlertStartTime;
        const StartTime = moment(this.props.pages.CustomAlertStartTime, 'HH:mm:ss');
        const EndTime = moment(this.props.pages.CustomAlertEndTime, 'HH:mm:ss');

        this.setState({
            CustomAlertTitle: this.props.pages.CustomAlertTitle,
            CustomAlertIcon: this.props.pages.CustomAlertIcon,
            CustomAlertStartData: StartDate,
            CustomAlertEndData: EndDate,
            CustomAlertStartTime: StartTime,
            CustomAlertEndTime: EndTime,
            ExistingItem: true
        })

    }

    DaySelected = (e) => {
        this.setState({
            CustomAlertStartData: e.from,
            CustomAlertEndData: e.to,
            Err: ''
        })
    }

    ApproveDaySelect = () => {
        this.ToggleDatePicker()
    }

    CancelDaySelect = () => {
        this.ToggleDatePicker()
    }

    SetCustomAlertIcon = (e) => {
        this.setState({
            CustomAlertIcon: e,
            Err: ''
        })
    }

    ToggleCreateAlert = () => {
        this.setState({
            CreateAlert: !this.state.CreateAlert
        })

    }


    ToggleGlotenFree = () => {
        this.setState({
            SelectedDishGlotenFree: !this.state.SelectedDishGlotenFree
        })
        this.props.pages.SelectedDishGlotenFree = !this.props.pages.SelectedDishGlotenFree
    }

    ToggleDatePicker = () => {
        this.setState({
            ShowDatePicker: !this.state.ShowDatePicker
        })
    }

    ToggleTimePicker = () => {
        this.setState({
            ShowTimePicker: !this.state.ShowTimePicker
        })
    }

    EndTimeSelect = (e) => {
        this.setState({
            CustomAlertEndTime: e,
            Err: ''
        })
    }

    StartTimeSelect = (e) => {
        console.log(e)
        this.setState({
            CustomAlertStartTime: e,
            Err: ''
        })
    }



    @action
    create_dish(data_to_post) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/create_dish`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.props.pages.updating = false
                    this.setState({ updatind: false })
                    this.CloseEdit()
                    if (document.getElementById('RefreshPage')) {
                        document.getElementById('RefreshPage').click()
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }

    UpdateExistingItem = () => {
        let Err = false
        if (this.state.CustomAlertTitle == '') {
            this.setState({
                Err: 'נא לכתוב כותרת להתראה'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertIcon == '') {
            this.setState({
                Err: 'נא לבחור אייקון לפרסום'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertStartData == '' || this.state.CustomAlertEndData == '') {
            this.setState({
                Err: 'נא לבחור תאריכים'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertStartTime == '') {
            this.setState({
                Err: 'נא לבחור שעת התחלה'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertEndTime == '') {
            this.setState({
                Err: 'נא לבחור שעת סיום'
            })
            Err = true
            return
        }

        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.alert_title = this.state.CustomAlertTitle // string
        data_to_post.alert_icon_name = this.state.CustomAlertIcon // string

        let start_date_today = new Date(this.state.CustomAlertStartData);
        let start_date_tomorrow = new Date(start_date_today);
        start_date_tomorrow.setDate(start_date_today.getDate() + 1);
        data_to_post.alert_start_date = start_date_tomorrow// string

        let end_date_today = new Date(this.state.CustomAlertEndData);
        let end_date_tomorrow = new Date(end_date_today);
        end_date_tomorrow.setDate(end_date_today.getDate() + 1);
        data_to_post.alert_end_date = end_date_tomorrow// string

        data_to_post.SelectedAlertId = this.props.pages.SelectedAlertId// string

        if (this.state.CustomAlertEndData === undefined) {
            data_to_post.alert_end_date = this.state.CustomAlertStartData// string
        }
        if (this.state.CustomAlertStartTime !== '') {
            data_to_post.alert_start_time = this.state.CustomAlertStartTime.format('HH:mm')// string
        }
        if (this.state.CustomAlertEndTime !== '') {
            data_to_post.alert_end_time = this.state.CustomAlertEndTime.format('HH:mm')// string
        }

        if (Err == false) {
            this.update_alert(data_to_post)
        }
    }


    @action
    update_alert(data_to_post) {

        const wordPressSiteUrl = clientConfig.siteUrl;
        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/update_alert`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.props.pages.updating = false
                    this.setState({ updatind: false })
                    this.CloseEdit()
                    this.props.pages.GetNotifications()
                    if (document.getElementById('get_notifications')) {
                        document.getElementById('get_notifications').click()
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }


    SaveData = () => {
        let Err = false

        if (this.state.CustomAlertTitle == '') {
            this.setState({
                Err: 'נא לכתוב כותרת להתראה'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertIcon == '') {
            this.setState({
                Err: 'נא לבחור אייקון לפרסום'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertStartData == '' || this.state.CustomAlertEndData == '') {
            this.setState({
                Err: 'נא לבחור תאריכים'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertStartTime == '') {
            this.setState({
                Err: 'נא לבחור שעת התחלה'
            })
            Err = true
            return
        }
        if (this.state.CustomAlertEndTime == '') {
            this.setState({
                Err: 'נא לבחור שעת סיום'
            })
            Err = true
            return
        }





        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.alert_title = this.state.CustomAlertTitle // string
        data_to_post.alert_icon_name = this.state.CustomAlertIcon // string

        let start_date_today = new Date(this.state.CustomAlertStartData);
        let start_date_tomorrow = new Date(start_date_today);
        start_date_tomorrow.setDate(start_date_today.getDate() + 1);
        data_to_post.alert_start_date = start_date_tomorrow// string

        let end_date_today = new Date(this.state.CustomAlertEndData);
        let end_date_tomorrow = new Date(end_date_today);
        end_date_tomorrow.setDate(end_date_today.getDate() + 1);
        data_to_post.alert_end_date = end_date_tomorrow// string


        if (this.state.CustomAlertEndData === undefined) {
            data_to_post.alert_end_date = this.state.CustomAlertStartData// string
        }
        if (this.state.CustomAlertStartTime !== '') {
            data_to_post.alert_start_time = this.state.CustomAlertStartTime.format('HH:mm')// string
        }
        if (this.state.CustomAlertEndTime !== '') {
            data_to_post.alert_end_time = this.state.CustomAlertEndTime.format('HH:mm')// string
        }

        if (Err == false) {
            this.create_custom_alert(data_to_post)
        }

    }



    @action
    create_custom_alert(data_to_post) {

        const wordPressSiteUrl = clientConfig.siteUrl;
        this.props.pages.updating = true
        this.setState({ updatind: true })
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/create_custom_alert`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.props.pages.updating = false
                    this.setState({ updatind: false })
                    this.CloseEdit()
                    this.props.pages.GetNotifications()
                    if (document.getElementById('get_notifications')) {
                        document.getElementById('get_notifications').click()
                    }
                });
            })
            .catch(err => {
                console.log(err)
            })
    }



    @action
    onChangeTextField = (e) => {
        const Name = e.target.name
        const Value = e.target.value
        this.props.pages[e.target.name] = e.target.value
        this.setState({
            [Name]: Value,
            DiningRoomsListErr: '',
            SelectedDishNameErr: '',
            CategoryErr: '',
            FoodCourtCategoryErr: '',
            Err: ''
        })
    }

    @action
    CloseEdit = () => {
        window.scrollTo({ top: -this.props.pages.CurrentScrollPosition, left: 0, behavior: "instant" })

        this.props.pages.CustomAlertTitle = ''
        this.props.pages.CustomAlertIcon = ''
        this.props.pages.CustomAlertStartDate = ''
        this.props.pages.CustomAlertStartTime = ''
        this.props.pages.CustomAlertEndDate = ''
        this.props.pages.CustomAlertEndTime = ''
        this.props.pages.SelectedAlertId = ''

        this.setState({
            CustomAlertTitle: '',
            CustomAlertIcon: '',
            CustomAlertStartData: '',
            CustomAlertStartTime: '',
            CustomAlertEndData: '',
            CustomAlertEndTime: '',
            updatind: false,
            Err: ''
        })
        this.props.pages.EditNotificationPopup = false
    }


    @action
    SetValue = (options) => {
        const CustomAlertTitle = toJS(this.props.pages.CustomAlertTitle)
        // const CustomAlertIcon = toJS(this.props.pages.CustomAlertIcon)
        // const CustomAlertStartData = toJS(this.props.pages.SelectedDishAlertStartDate)
        // const StartDatedateMomentObject = moment(SelectedDishAlertStartDate, "DD/MM/YYYY");
        // const StartDatedateObject = StartDatedateMomentObject.toDate();

        // const SelectedDishAlertEndDate = toJS(this.props.pages.SelectedDishAlertEndDate)
        // const EndDatedateMomentObject = moment(SelectedDishAlertEndDate, "DD/MM/YYYY");
        // const EndDatedateObject = EndDatedateMomentObject.toDate();
        // const SelectedDishCustomAlertStartTime = moment(this.props.pages.SelectedDishCustomAlertStartTime, "HH:mm:ss")
        // const SelectedDishCustomAlertEndTime = moment(this.props.pages.SelectedDishCustomAlertEndTime, "HH:mm:ss")
        this.setState({
            // CustomAlertTitle: CustomAlertTitle,
            // CustomAlertIcon: CustomAlertIcon,
            // CustomAlertStartData: CustomAlertStartData,
            // CustomAlertEndData: '',
            // CustomAlertStartTime: '',
            // CustomAlertEndTime: '',
        })

    }

    render() {
        const style = {
            right: this.props.pages.EditNotificationPopup == true ? '0px' : '-150%',
        }

        const CloseX = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L16 16" stroke="#4B4747" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 2L2 16" stroke="#4B4747" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>



        let EditAlertData = null
        const ArrowDown = <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.14844 1.11108L4.90613 4.97186C5.30495 5.53021 6.13478 5.53021 6.5336 4.97186L9.29129 1.11108" stroke="#49C9D1" stroke-width="3" />
        </svg>

        if (this.state.CreateAlert) {
            let DateFromTo = 'בחר תאריכים'
            const now = moment().hour(0).minute(0);
            const format = 'h:mm a';

            if (this.state.CustomAlertStartData !== '') {
                DateFromTo = moment(this.state.CustomAlertStartData).format('DD/MM')
            }

            if (this.state.CustomAlertEndData !== '') {
                const from = moment(this.state.CustomAlertStartData).format('DD/MM')
                const to = moment(this.state.CustomAlertEndData).format('DD/MM')
                DateFromTo = `${to} - ${from}`
            }


            EditAlertData = <>

                <MuiTextField
                    value={this.state.CustomAlertTitle}
                    name={'CustomAlertTitle'}
                    placeholder="כותרת התראה"
                    onChange={this.onChangeTextField.bind(this)}
                    FieldDescription={'כותרת התראה'}
                    marginBottom={'20px'}
                />

                <SectionTitle Title={'בחר/י אייקון מהרשימה לפרסום '} />
                <div className={styles.AlertIcons}>
                    <div className={styles.AlertIconsRow}>

                        <CustomAlertIcon width='50px'
                            Icon={this.props.links.IconFoodCover}
                            IconWhite={this.props.links.IconFoodCoverWhite}
                            Selected={this.state.CustomAlertIcon == 'food_cover'}
                            OnClick={this.SetCustomAlertIcon.bind(this, 'food_cover')}
                        />

                        <CustomAlertIcon width='60px'
                            Icon={this.props.links.IconPlate}
                            IconWhite={this.props.links.IconPlateWhite}
                            Selected={this.state.CustomAlertIcon == 'plate'}
                            OnClick={this.SetCustomAlertIcon.bind(this, 'plate')}
                        />


                        <CustomAlertIcon width='45px'
                            Icon={this.props.links.IconSalting}
                            IconWhite={this.props.links.IconSaltingWhite}
                            Selected={this.state.CustomAlertIcon == 'salting'}
                            OnClick={this.SetCustomAlertIcon.bind(this, 'salting')}
                        />
                    </div>

                    <div className={styles.AlertIconsRow}>
                        <CustomAlertIcon width='45px'
                            Icon={this.props.links.IconApron}
                            IconWhite={this.props.links.IconApronWhite}
                            Selected={this.state.CustomAlertIcon == 'apron'}
                            OnClick={this.SetCustomAlertIcon.bind(this, 'apron')}
                        />
                        <CustomAlertIcon width='45px'
                            Icon={this.props.links.IconSilvers}
                            IconWhite={this.props.links.IconSilversWhite}
                            Selected={this.state.CustomAlertIcon == 'silvers'}
                            OnClick={this.SetCustomAlertIcon.bind(this, 'silvers')}
                        />
                        <CustomAlertIcon width='50px'
                            Icon={this.props.links.IconHat}
                            IconWhite={this.props.links.IconHatWhite}
                            Selected={this.state.CustomAlertIcon == 'hat'}
                            OnClick={this.SetCustomAlertIcon.bind(this, 'hat')}
                        />
                    </div>
                </div>

                <div className={styles.DateAndTime}>
                    <SectionTitle Title={'הכנס/י תאריך וזמן הפוגה להתראה'} />

                    <div className={styles.DateAndTimeContiner}>
                        <div className={styles.Date} onClick={this.ToggleDatePicker.bind(this)}>
                            <div className={styles.Helper}>מתאריך עד תאריך</div>
                            <div className={styles.Text}>
                                {DateFromTo}
                            </div>
                            <div className={styles.ArrowDown}>{ArrowDown}</div>
                        </div>
                    </div>

                    <div className={styles.DateAndTimeContiner}>
                        <div className={styles.Time} >
                            <div className={styles.Helper}>שעת התחלה</div>

                            <TimePicker
                                showSecond={false}
                                defaultValue={now}
                                className={styles.TimePicker}
                                onChange={this.StartTimeSelect.bind(this)}
                                format={format}
                                inputReadOnly
                                value={this.state.CustomAlertStartTime}
                            />


                        </div>
                        <div className={styles.Gap}></div>
                        <div className={styles.Time} >
                            <div className={styles.Helper}>שעת סיום</div>
                            <TimePicker
                                showSecond={false}
                                defaultValue={now}
                                className={styles.TimePicker}
                                onChange={this.EndTimeSelect.bind(this)}
                                format={format}
                                inputReadOnly
                                value={this.state.CustomAlertEndTime}
                            />
                        </div>
                    </div>


                    {this.state.ShowDatePicker ?
                        <>
                            <DateRangePicker
                                ApproveDaySelect={this.ApproveDaySelect.bind(this)}
                                CancelDaySelect={this.CancelDaySelect.bind(this)}
                                DaySelected={this.DaySelected.bind(this)} />
                        </>
                        : null}
                    {this.state.ShowTimePicker ?
                        <>
                            <MuiTimePicker />
                        </>
                        : null}
                </div>
            </>
        }

        let SubmitButton = <div onClick={this.state.loading ? null : this.SaveData.bind(this)} className={styles.SaveButton}>{this.state.ExistingItem ? 'עדכון' : 'הוספת התראה'}</div>
        if (this.state.ExistingItem) {
            SubmitButton = <div onClick={this.state.loading ? null : this.UpdateExistingItem.bind(this)} className={styles.SaveButton}>{this.state.ExistingItem ? 'עדכון' : 'הוספת התראה'}</div>
        }
        const ProgressAnimation = <div className={styles.SpinnerAnimation}><img src={this.props.links.SpinnerAnimation} /></div>


        return (
            <div className={styles.MainContainer} style={style}>
                <div className={styles.HiddenButton} id='set_edit_notification_items' onClick={(this.SetItems.bind(this))} >SET ITEMS</div>
                <div className={styles.FixedBack}></div>
                {this.state.updatind == true ? ProgressAnimation : null}
                <div className={styles.Background}></div>
                <div className={styles.EditItemContainer}>
                    <div className={styles.HideEditContainer}>
                        <div className={styles.HideEdit} onClick={this.CloseEdit.bind(this)}> {CloseX} </div>
                    </div>
                    <div className={styles.Form}>


                        <div className={styles.EditAlertContainer}>
                            {EditAlertData}
                        </div>


                    </div>
                </div>
                {SubmitButton}
                <div className={styles.Err}>{this.state.Err}</div>
                <div style={{ paddingBottom: '30px', color: 'white' }}>-</div>
            </div >
        )
    }
}
export default EditNotificationPopup;