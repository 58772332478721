import React from "react";
import styles from './WeekFavouritesItem.module.css';
import { Skeleton } from "@mui/material";

class WeekFavouritesItem extends React.Component {
    constructor() {
        super();
        this.state = {
            HeartFill: '#FF563F',
            HeartOutLine: '#FF563F'
        };
    }

    componentDidMount() {

    }
    HeartClick = () => {
        if (this.state.HeartFill == 'white') {
            this.setState({
                HeartFill: '#FF563F',
                HeartOutLine: '#FF563F'
            })
        }
        if (this.state.HeartFill == '#FF563F') {
            this.setState({
                HeartFill: 'white',
                HeartOutLine: '#4B4747'
            })
        }

    }

    render() {
        const Heart = <svg width="100%" height="100%" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.62 18.81C11.28 18.93 10.72 18.93 10.38 18.81C7.48 17.82 1 13.69 1 6.68998C1 3.59998 3.49 1.09998 6.56 1.09998C8.38 1.09998 9.99 1.97998 11 3.33998C12.01 1.97998 13.63 1.09998 15.44 1.09998C18.51 1.09998 21 3.59998 21 6.68998C21 13.69 14.52 17.82 11.62 18.81Z" stroke={this.state.HeartOutLine} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill={this.state.HeartFill} />
        </svg>
        const HeartGray = <svg width="100%" height="100%" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.62 18.81C11.28 18.93 10.72 18.93 10.38 18.81C7.48 17.82 1 13.69 1 6.68998C1 3.59998 3.49 1.09998 6.56 1.09998C8.38 1.09998 9.99 1.97998 11 3.33998C12.01 1.97998 13.63 1.09998 15.44 1.09998C18.51 1.09998 21 3.59998 21 6.68998C21 13.69 14.52 17.82 11.62 18.81Z" stroke={'#e1e1e1'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill={'white'} />
        </svg>
        let content = <>
            <div className={styles.Icon}>
                {Heart}
                <div className={styles.Number}>{this.props.Number}</div>
            </div>
            <h1>{this.props.DishName}</h1>
            <h2>{this.props.DiningRoom}</h2>
        </>
        if (this.props.loading) {
            content = <>
                <div className={styles.Icon}>
                    {HeartGray}
                    <div className={styles.Number}>-</div>
                </div>
                <Skeleton style={{ width: '50%', right: '20px', position: 'relative' }} />
            </>
        }
        return (
            <div className={styles.MainContainer} onClick={this.props.onClick}>
                <div className={styles.Container}>
                    {content}
                </div>
            </div>
        )
    }
}
export default WeekFavouritesItem;