const clientConfig = {
    SiteName: process.env.REACT_APP_API_KEY_SITE_NAME,
    
    siteUrl: process.env.REACT_APP_WORDPRESS, // Database URL
    siteMedia: process.env.REACT_APP_WORDPRESS + '/wp-json/wp/v2/media',
    siteLink: process.env.REACT_APP_SITE_LINK,

    NodeJsServer: process.env.REACT_APP_NODEJS_SERVER,
    GlobalServer: process.env.REACT_APP_GLOBAL_SERVER,

    AUTH_KEY: process.env.REACT_APP_API_KEY,

    PrimaryColorValue: process.env.REACT_APP_primaryColor,
    SecondaryColorValue: process.env.REACT_APP_secondaryColor,
    TertiaryColorValue: process.env.REACT_APP_tertiaryColor,
    // Change colors value slso in Colors.Css

    // colors value are in ./public/client_style.scss
    primaryColor: 'primaryColor',
    secondaryColor: 'secondaryColor',
    tertiaryColor: 'tertiaryColor',
    primaryFillColor: 'primaryFillColor',
    secondaryFillColor: 'secondaryFillColor',
    tertiaryFillColor: 'tertiaryFillColor',
    primaryBackgroundColor: 'primaryBackgroundColor',
    secondaryBackgroundColor: 'secondaryBackgroundColor',
    tertiaryBackgroundColor: 'tertiaryBackgroundColor',
    primaryFont: 'primaryFont',
    secondaryFont: 'secondaryFont',
};
export default clientConfig;
