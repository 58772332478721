import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './styles/Login.module.css';
import Star from '../components/Star'
import EnterButton from '../components/EnterButton';

@inject("login", "links")
@observer
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            EnterButtonClicked: false,
            showStars: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                showStars: true
            })
        }, 500);
    }



    EnterButtonClicked = () => {
        this.setState({ EnterButtonClicked: true })
        this.props.EnterButtonClicked()
    }

    render() {

        let MainContainerStyle = {
            backgroundImage: `url(${this.props.links.FoodPattern})`
        }

        let LoginImageAndStarsStyle = null
        let EnterButtonStyle = null
        let LoginTextStyle = { top: '370px' }
        let SpinnerStyle = { opacity: 0 }

        if (this.state.EnterButtonClicked) {
            LoginImageAndStarsStyle = { top: '30vh' }
            EnterButtonStyle = { opacity: 0 }
            SpinnerStyle = { opacity: 100 }
            LoginTextStyle = { top: '540px' }
        }
        const Button = <EnterButton onClick={this.EnterButtonClicked.bind(this)} style={EnterButtonStyle} />


        let Stars = null
        if (this.state.showStars) {
            Stars = <>
                <Star size={25} className={styles.Star1 + ' ' + styles.Postion1} />
                <Star size={25} className={styles.Star2 + ' ' + styles.Postion2} />
                <Star size={15} className={styles.Star1 + ' ' + styles.Postion3} />
                <Star size={15} className={styles.Star2 + ' ' + styles.Postion4} />
                <Star size={25} className={styles.Star2 + ' ' + styles.Postion5} />
            </>
        }
        let Spinner = null
        if (this.state.EnterButtonClicked == true) {
            Spinner = <div className={styles.Spinner} style={SpinnerStyle}>
                <img src={this.props.links.Spinner}></img>
            </div>
        }
        return (
            <div className={styles.MainContainer} style={MainContainerStyle}>
                <div className={styles.LoginConatiner}>
                    <div className={styles.LoginImageAndStars} style={LoginImageAndStarsStyle} >
                        <div className={styles.StarsContainer}>
                            {Stars}
                        </div>
                        {Spinner}
                        <div className={styles.LogoMenu}><img src={this.props.links.LogoMenu}></img></div>
                    </div>
                    <div className={styles.LoginText} style={LoginTextStyle}>
                        <h2>מה נאכל היום?</h2>
                        {/* <h3>מרכז התפריטים של ההסעדה ברפאל</h3> */}
                    </div>
                    {Button}
                </div>
            </div>
        );


    }
}

export default Login;
