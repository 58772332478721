import React from "react";
import styles from './DaysSliderItem.module.css';
import CloseOpenButton from './CloseOpenButton'
import { inject, observer } from 'mobx-react';

@inject("login", "links", "pages")
@observer
class DaysSliderItem extends React.Component {
    componentDidMount() {

    }


    render() {

        const loggedIn = this.props.login.loggedIn
        const UserRoles = this.props.login.UserRoles
        let UserType = 'webapp_user'
        if (loggedIn) {
            const isAdmin = UserRoles.includes("webapp_admin")
            if (isAdmin) {
                UserType = 'webapp_admin'
            }
        }



        let OpenClose = <></>
        if (UserType == 'webapp_admin') {
            OpenClose = <CloseOpenButton />
        }

        return (
            <div style={{ opacity: this.props.isBefore ? '0' : '1' }} id={this.props.id} className={styles.MainContainer}
                onClick={this.props.isBefore ? null : this.props.onClick}>
                <div className={styles.Container + ' slideItem'} >
                    <h1>{this.props.Date}</h1>
                    <div className="OpenCloseBt">{OpenClose}</div>
                </div>

            </div>
        )
    }
}
export default DaysSliderItem;