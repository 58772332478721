import { observable, configure, runInAction, action, makeAutoObservable } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';
import Cookies from 'universal-cookie';
import { enableStaticRendering } from "mobx-react";
import { Accessibility } from 'accessibility/dist/main';
import Pages from './Pages';

enableStaticRendering(typeof window === 'undefined')
configure({ enforceActions: true });

class Login {
    @observable loading = false
    @observable loggedIn = false
    @observable primaryColor = '#49C9D1'
    @observable secondaryColor = '#C5A0FA'
    @observable tertiaryColor = '#F5C037'
    @observable SiteLanguage = 'he' //deafult is he
    @observable SiteDiretion = 'right'
    @observable Menu = []
    @observable MenuSelectedItem = ''
    @observable IsMobileMenuOpen = false

    constructor() {
        makeAutoObservable(this);
        // this.InitiateSiteLanguage();

        const cookies = new Cookies();

        const current = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(current.getFullYear() + 1);

        const token = localStorage.getItem('token')

        let DishesLikesCookies = cookies.get('DishesLikes')
        if (DishesLikesCookies == undefined) {
            const Arr = ['0000']
            cookies.set('DishesLikes', Arr, {
                path: '/',
                expires: nextYear,
            });
        }

        if (token) {
            this.token = token
            this.ValidateToken(token)
        }
    };




    @action
    ValidateToken(token) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const headers = { Authorization: `Bearer ${token}` };
        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/jwt-auth/v1/token/validate`, headers)
            .then(res => {


                if (res?.data?.data?.status == 200) {
                    const user_roles = localStorage.getItem('user_roles')
                    
                    if (user_roles) {
                        runInAction(() => {
                            this.loggedIn = true
                            this.UserRoles = user_roles
                        });
                    }
                }
            })
            .catch(err => {
                localStorage.removeItem('token')
                localStorage.removeItem('user_roles')
                this.loggedIn = false
            })
    }

    @action
    InitiateSiteLanguage() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const Urllang = urlParams.get('lang')
        // console.log(document.documentElement)
        if (Urllang === 'en') {
            this.SiteLanguage = Urllang
            document.documentElement.lang = Urllang
            document.body.style.direction = "ltr";
        }
    }

    @action
    GetSiteSetting() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const PageId = '50'
        this.loading = true
        // console.log(this.loading)
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${PageId}?skip_cache=1`)
            .then(res => {
                console.log(res.data)
                runInAction(() => {

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPage ERR: ' + err)
                });
            })
    };


    @action
    GetMenue(forcelanguae, itemid) {
        const queryString = window.location.search;
        const PathName = decodeURI(window.location.pathname)
        const urlParams = new URLSearchParams(queryString);
        const Urllang = urlParams.get('lang')
        let menuId = 65

        if (Urllang !== null) {
            if (Urllang === 'en') {
                menuId = 66
            }
        }

        this.loading = true
        const wordPressSiteUrl = clientConfig.siteUrl;
        // console.log(wordPressSiteUrl)

        let lang = '?lang=' + this.SiteLanguage


        if (forcelanguae !== undefined) {
            lang = '?lang=' + forcelanguae
            this.SiteLanguage = forcelanguae
            menuId = 66
        }
        if (forcelanguae === 'he' || this.SiteLanguage === 'he') {
            lang = ''
        }


        axios.get(`${wordPressSiteUrl}/wp-json/custom/menu/${menuId}/${lang}`)
            .then(res => {
                // console.log(res.data)
                Pages.GetHomePage()
                const GetItemSelected = res.data.filter(item => PathName.includes(item.title.replace(/\s/g, '-').toLowerCase()));
                runInAction(() => {
                    this.MenuSelectedItem = res.data[0].ID
                    this.Menu = res.data
                    if (GetItemSelected.length > 0) {
                        this.MenuSelectedItem = GetItemSelected[0].ID
                    }
                    if (itemid !== undefined) {
                        console.log(itemid)
                    }
                    this.loading = false
                });
            })
            .catch(err => {
                runInAction(() => {
                    console.log(err)
                    this.loading = false
                });
            })
    };

    @action
    SetFirstLoginEnd() {
        const cookies = new Cookies();
        cookies.set('FirstLogin', false, { path: '/' });
        this.firstLogin = false
    }


    @action
    SetErrMessage(message) {
        this.LogErrMessage = message
        this.ShowlogErr = true
    }

    @action
    HideErr() {
        this.ShowlogErr = false
    }


    @action
    Logout() {
        localStorage.removeItem('user_roles');
        localStorage.removeItem('token');
        this.loggedIn = false
        this.token = null
    }

    @action
    stringToBoolean(string) {
        switch (string.toLowerCase().trim()) {
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    }

};

export default new Login();