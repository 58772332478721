import React, { Component } from 'react';

import { action, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';

import styles from './styles/Header.module.css';

import { slide as Menu } from 'react-burger-menu'
import MenuItem from './MenuItem';

import { HiMenuAlt3 } from 'react-icons/hi';
import { FaChevronLeft } from 'react-icons/fa';
import { FaRegFileExcel } from "react-icons/fa";
import packageJson from '../../package.json';

@inject("login", "links", "pages")
@observer
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuIsOpen: false,
            ShowPrevArrow: false,
            EditItemPopUp: false
        }
    }

    ClearUrl = () => {

    }

    @action
    ToggleEditItemPopUp = () => {
        this.props.pages.EditItemPopUp = true
        this.setState({
            EditItemPopUp: !this.state.EditItemPopUp
        })
    }

    ToggleMenu = () => {
        this.setState({
            menuIsOpen: !this.state.menuIsOpen
        })
    }

    GotoPrev = () => {
        this.props.GotoPrev()
    }

    MenuItemClick = (e) => {
        this.props.pages.EditItemPopUp = false
        if (e == 'logout') {
            this.props.login.Logout()
            this.ToggleMenu()
            return
        }
        this.props.SetPage(e)
        this.ToggleMenu()
    }

    render() {

        const menuStyles = {
            bmBurgerButton: {
                position: 'fixed',
                width: '36px',
                height: '30px',
                left: '36px',
                top: '36px'
            },
            bmBurgerBars: {
                background: '#373a47'
            },
            bmBurgerBarsHover: {
                background: '#a90000'
            },
            bmCrossButton: {
                height: '24px',
                width: '24px'
            },
            bmCross: {
                background: '#bdc3c7'
            },
            bmMenuWrap: {
                position: 'fixed',
                height: '100%'
            },
            bmMenu: {
                background: 'white',
                padding: '2.5em 1.5em 0',
                fontSize: '1.15em'
            },
            bmMorphShape: {
                fill: '#373a47'
            },
            bmItemList: {
                color: '#b8b7ad',
                padding: '0.8em'
            },
            bmItem: {
                display: 'inline-block'
            },
            bmOverlay: {
                background: 'rgba(0, 0, 0, 0.3)'
            }
        }

        let PrevArrow = null
        if (this.props.ShowPrevArrow) {
            PrevArrow = <div className={styles.FaChevronLeft} onClick={this.GotoPrev.bind(this)}> <FaChevronLeft size={25} /></div>
        }

        const loggedIn = this.props.login.loggedIn
        const UserRoles = this.props.login.UserRoles
        let UserType = 'webapp_user'
        if (loggedIn) {
            const isAdmin = UserRoles.includes("webapp_admin")
            if (isAdmin) {
                UserType = 'webapp_admin'
            }
        }

        let LoginOut = <MenuItem
            Text="התחבר"
            Icon={this.props.links.IconLogin}
            IconWidth='25px'
            MarginTop='25px'
            onClick={this.MenuItemClick.bind(this, 'login')}
        />

        let FromExcelTable = <></>
        if (loggedIn) {
            LoginOut = <MenuItem
                Text="התנתק"
                Icon={this.props.links.IconLogout}
                IconWidth='25px'
                MarginTop='25px'
                onClick={this.MenuItemClick.bind(this, 'logout')}
            />
            FromExcelTable = <MenuItem
                Text="עדכון מקובץ"
                Icon={this.props.links.IconExcel}
                IconWidth='25px'
                MarginTop='25px'
                onClick={this.MenuItemClick.bind(this, 'from_file')}
            />

        }


        return (
            <>

                <Menu isOpen={this.state.menuIsOpen} right className={styles.Menu}>
                    <div style={{ cursor: "pointer" }} onClick={this.ToggleMenu.bind(this)}><img src={this.props.links.Xnav} /></div>
                    <MenuItem
                        Text="דף הבית"
                        Icon={this.props.links.IconHome}
                        IconWidth='25px'
                        onClick={this.MenuItemClick.bind(this, 'home')}
                    />
                    <MenuItem
                        Text="תפריטים"
                        Icon={this.props.links.IconBookOpen}
                        IconWidth='25px'
                        onClick={this.MenuItemClick.bind(this, 'menus')}
                    />
                    <MenuItem
                        Text="התראות"
                        Icon={this.props.links.IconBell}
                        IconWidth='25px'
                        onClick={this.MenuItemClick.bind(this, 'notifications')}
                    />
                    <MenuItem
                        Text="צור קשר"
                        Icon={this.props.links.IconPhone}
                        IconWidth='25px'
                        onClick={this.MenuItemClick.bind(this, 'contact')}
                    />
                    {/* <MenuItem
                        Text="הגדרות"
                        Icon={this.props.links.IconSetting}
                        IconWidth='25px'
                        onClick={this.MenuItemClick.bind(this, 'setting')}
                    /> */}
                    <MenuItem
                        Text="מידע לעובד"
                        Icon={this.props.links.IconInfo}
                        IconWidth='25px'
                        onClick={this.MenuItemClick.bind(this, 'info')}
                    />
                    <div className={styles.BottomSection}>
                        <MenuItem
                            Text="דף עובד"
                            Icon={this.props.links.IconUser}
                            IconWidth='25px'
                            MarginTop='25px'
                            onClick={this.MenuItemClick.bind(this, 'user_page')}
                        />
                        {FromExcelTable}
                        {LoginOut}
                    </div>

                    <div className={styles.Version}>Version:{packageJson.version}</div>
                </Menu>

                <div className={styles.MainContainer} style={{ height: this.props.HeaderHeight }}>



                    <div className={styles.HeaderContiner}>
                        <div className={styles.Right} >
                            <div className={styles.HiMenuAlt3} onClick={this.ToggleMenu.bind(this)}><HiMenuAlt3 size={35} /></div>
                        </div>
                        <div className={styles.Center}>
                            <div className={styles.LogoMenu}><img src={this.props.links.LogoMenu}></img></div>
                        </div>
                        <div className={styles.Left}>
                            {PrevArrow}
                        </div>
                    </div>
                </div>
            </>
        );


    }
}

export default Header;
