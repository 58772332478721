import React, { Component, Suspense } from 'react';
import Styles from './SliderDots.module.css';
import { inject, observer } from 'mobx-react';
import { TextField, Box } from '@mui/material';
import clientConfig from '../../client-config';


@inject("login", "links", "pages")
@observer
class SliderDots extends Component {

    constructor() {
        super();
        this.state = {

        };
    }



    onClickItem = (e) => {
        this.props.onClickItem(e)
    }

    render() {
        const SlidesArr = [1, 2, 3, 4, 5, 6]
        const SlidesLength = SlidesArr.length
        const slideIndex = this.props.slideIndex
       
        let Slider = this.props.Notifications.map((item, index) => {
            let Item = <div onClick={this.onClickItem.bind(this, index)} className={Styles.Item}>  </div>
            if (index === slideIndex) {
                Item = <div onClick={this.onClickItem.bind(this, index)} className={Styles.SelectedItem}>  </div>
            }
            return (
                Item
            )
        })






        return (
            <div className={Styles.MainContainer}>
                <div className={Styles.Container}>
                    {Slider}
                    {/* <div className={Styles.Item}>  </div>
                    <div className={Styles.SelectedItem}>  </div>
                    <div className={Styles.Item}>  </div>
                    <div className={Styles.Item}>  </div>
                    <div className={Styles.Item}>  </div>
                    <div className={Styles.Item}>  </div> */}
                </div>
            </div>

        );


    }
}
export default SliderDots;

