import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './styles/CreateNewInfo.module.css';
import { action, toJS } from 'mobx';

@inject("login", "links", "pages")
@observer
class CreateNewInfo extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    @action
    onClick = () => {
        this.props.pages.NewInfoItem = true
        this.props.pages.CurrentScrollPosition = document.body.getBoundingClientRect().top
        window.scrollTo({ top: 0, left: 0, behavior: "instant" })
        this.props.pages.InfoTitle = ""
        this.props.pages.InfoDetails = ""
    
        // if (document.getElementById('set_InfoTitle')) {
        //     document.getElementById('set_InfoTitle').click()
        // }

        this.props.pages.EditInfoPopUp = true
    }

    render() {
        const Plus = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 9.0108H16" stroke="white" stroke-width="3" stroke-linecap="round" />
            <path d="M9 2.0108L9 16.0108" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>

        let PageContent = null
        if (this.props.UserType == 'webapp_admin') {
            PageContent = <div className={styles.MainContainer}  >
                <div onClick={this.onClick.bind(this)} className={styles.AddDish}>
                    {Plus}
                    <h1>הוספת מידע לעובד</h1>
                </div>
            </div>
        }

        return (
            <>
                {PageContent}
            </>

        );


    }
}

export default (CreateNewInfo);