import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Cookies from 'universal-cookie';
import AppPackage from '../../package.json'
import { Helmet } from "react-helmet";

@inject("login")
@observer
class LoginCheck extends Component {
    constructor(props) {
        super(props)
        this.state = {
            trigger_update: true,
            clearCache: false

        }
    }



    componentDidMount() {
        // const cookies = new Cookies();
        // const AppVersion = AppPackage.version
        // const CookeisAppVersion = cookies.get('AppVersion');
        // if (CookeisAppVersion !== 'undefined') {
        //     if (AppVersion !== CookeisAppVersion) {
        //         cookies.set('AppVersion', AppVersion);
        //         console.log('New Version! Cache cleared')
        //         this.setState({ clearCache: true })
        //     }

        // }
        // if (CookeisAppVersion === 'undefined') {
        //     cookies.set('AppVersion', AppVersion);
        // }
    }

    render() {

        const clearCache = this.state.clearCache
        let AddClearToHelmet = null
        if (clearCache) {
            AddClearToHelmet =
                <Helmet>
                    <meta http-equiv='cache-control' content='no-cache' />
                    <meta http-equiv='expires' content='0' />
                    <meta http-equiv='pragma' content='no-cache' />
                </Helmet>
        }

        return (
            <>


                {this.props.loggedin ?
                    <>
                        {AddClearToHelmet}
                        {this.props.children}</>
                    :
                    <Redirect push to={`/`} />
                }
            </>
        );


    }
}

export default LoginCheck;
