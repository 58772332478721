import { observable, configure, runInAction, action, makeAutoObservable, toJS } from 'mobx';
import axios from 'axios';
import clientConfig from '../client-config';
import { enableStaticRendering } from "mobx-react"
import moment from 'moment';
import Cookies from 'universal-cookie';

enableStaticRendering(typeof window === 'undefined')
configure({ enforceActions: true });

class Pages {
    @observable CloseDatePageId = ''
    @observable SlectedCatId = ''

    @observable updating = false

    @observable EditItemPopUp = false
    @observable EditElergenimPopUp = false
    @observable EditInfoPopUp = false
    @observable EditNotificationPopup = false

    @observable loading = false

    @observable NotificationsList = []
    @observable loadingSingleProject = false

    //PAGES NAVIGATION
    @observable SelectedDate = ''
    @observable SelectedPage = 'home'
    @observable SelectedPageCloseDates = []
    @observable IsopenToday = true
    @observable PrevArr = ['home']

    @observable Setting = []
    @observable DiningRooms = [] // רשימת חדרי אוכל
    @observable DiningRoomsLoading = true

    @observable Dishes = [] // כל המנות

    //חדר אוכל
    @observable DinningRoomCloseDates = []
    @observable DrCulinaryTourItems = [] // סיור קולינרי
    @observable DrVeganCulinaryTourItems = [] // סיור קולינרי טבעוני
    @observable DrSoupItems = [] //מרקים
    @observable DrCaughtInTheNetItems = [] //נתפס ברשת
    @observable DrRoastedOnTheGrillItems = [] //צלוי על הָאַסְכָּלָה
    @observable DrKokurikoItems = [] //קוקוריקו
    @observable DrMammothDishesItems = [] //תבשילי מאמות
    @observable DrVegItems = [] //צמחוני טבעוני
    @observable DrExtrasItems = [] //תוספות
    @observable DrSaladItems = [] //סלטים
    @observable DrDessertsItems = [] //משהו לקינוח

    @observable ShefItems = []
    @observable SoupItems = []
    @observable FishItems = []
    @observable MeatItems = []
    @observable VegItems = []
    @observable ExtrasItems = []
    @observable SaladItems = []
    @observable DessertsItems = []
    //חדר אוכל END

    //הדרן
    @observable HadranCloseDates = []
    @observable HadranCulinaryTourItems = [] // סיור קולינרי
    @observable HadranVeganCulinaryTourItems = [] // סיור קולינרי טבעוני
    @observable HadranSoupItems = [] //מרקים
    @observable HadranCaughtInTheNetItems = [] //נתפס ברשת
    @observable HadranRoastedOnTheGrillItems = [] //צלוי על הָאַסְכָּלָה
    @observable HadranKokurikoItems = [] //קוקוריקו
    @observable HadranVegetableStewsItems = [] //תבשילי ירקות
    @observable HadranVegItems = [] //צמחוני טבעוני
    @observable HadranPachmemaItems = [] //פחמימה
    @observable HadranSaladItems = [] //סלטים
    @observable HadranDessertsItems = [] //משהו לקינוח

    //פסטה פיורי
    @observable PastaFuriFirstDish = []
    @observable PastaFuriPastasTypes = []
    @observable PastaFuriSaucesTypes = []
    @observable PastaFuriMeatExtra = []
    //פסטה פיורי END

    //חומוס ירושלים
    @observable HummusDishesToChoose = []
    @observable HummusExtra = [] // תוספות
    //חומוס ירושלים END

    //אדיסון
    @observable AdisonHamburger = []
    @observable AdisonBunExtra = []  // תוספות בלחמניה
    @observable AdisonExtra = [] // תוספות
    //אדיסון END

    // Ohashi 
    @observable OhashiFirstDishes = []
    @observable OhashiMainCourse = []
    // Ohashi END

    // Hastekiya 
    @observable HastekiyaDishesToChoose = []
    @observable HastekiyaExtra = []
    // Hastekiya END


    //סלט בהרכבה
    @observable VegetablesItems = []
    @observable SaladExtraItem = []
    @observable SaucesItems = []
    @observable SaladHelbonItems = []
    //סלט בהרכבה END

    //ללא גלוטן
    @observable GlotenFreeDiningRoomItems = []
    @observable GlotenFreeEdisonItems = []
    //ללא גלוטן END

    //מהדרין
    @observable MehadrinDishesToSelect = []
    //מהדרין END

    //בית קפה
    @observable CoffeShopDeal = []
    @observable BussinesFirst = []
    @observable BussinesMain = []
    @observable BussinesDrink = []
    @observable BussinesDessert = []
    @observable HotDrink = []
    //בית קפה END

    @observable HomePageContent = ''
    @observable HomePageACF = ''
    @observable HomePageContentReady = false

    @observable AboutPageACF = ''

    @observable Projects = ''
    @observable ProjectsCategories = ''

    @observable SingleProject = []


    //CUSTOM NOTIFICATION
    @observable CustomAlertTitle = ''
    @observable CustomAlertIcon = ''
    @observable CustomAlertStartDate = ''
    @observable CustomAlertEndDate = ''
    @observable CustomAlertStartTime = ''
    @observable CustomAlertEndTime = ''
    @observable SelectedAlertId = ''
    //CUSTOM NOTIFICATION END

    // EDIT/CREATE ITEM 
    @observable CategoriesList = []
    @observable FoodcourtCategoriesList = []
    @observable NewDish = false
    @observable SelectedDiningRoom = []
    @observable SelectedCategory = ''
    @observable SelectedFoodcourtCategory = []
    @observable SelectedItemId = ''
    @observable SelectedDishName = ''
    @observable SelectedDishIngedients = ''
    @observable SelectedDishComment = ''
    @observable SelectedDishCreateAlert = false
    @observable SelectedDishAlertOldIdToRemove = ''
    @observable SelectedDishAlertTitle = ''
    @observable SelectedDishAlertIcon = ''
    @observable SelectedDishAlertStartDate = ''
    @observable SelectedDishAlertEndDate = ''
    @observable SelectedDishAlertStartTime = ''
    @observable SelectedDishAlertEndTime = ''
    @observable SelectedDishGlotenFree = false
    @observable SelectedDishPrice = ''

    @observable CurrentTab = 'tab1' // tab to scroll to when close edit
    @observable CurrentScrollPosition = ''
    // EDIT/CREATE ITEM END

    // EDIT/CREATE Elergenim ITEM
    @observable NewElergenimItem = false
    @observable SelectedElergenimPageId = ""
    @observable ElergenimTitle = ""
    @observable ElergenimManufacturer = ""
    @observable ElergenimContain = ""
    @observable ElergenimMayContain = ""
    @observable ElergenimCashrot = ""
    // EDIT/CREATE Elergenim ITEM END

    // EDIT/CREATE INFO ITEM
    @observable NewInfoItem = false
    @observable SelectedInfoPageId = ""
    @observable InfoTitle = ""
    @observable InfoDetails = ""
    // EDIT/CREATE INFO ITEM END


    @observable SearchResults = ""
    @observable SearchFilterDiningRoom = []
    @observable SearchFilterHadran = []
    @observable SearchFilterFoodcourt = []
    @observable SearchFilterStake = []
    @observable SearchFilterSalad = []
    @observable SearchFilterPasta = []
    @observable SearchFilterCoffeShop = []
    @observable SearchFilterMhadrin = []
    @observable SearchFilterGlutenFree = []

    @observable MyLikes = []

    @observable DiningRoomAllItems = []
    @observable FoodcourtPastaFuriAllItems = []
    @observable FoodcourtHummusAllItems = []
    @observable FoodcourtAdisonAllItems = []
    @observable StekiyaAllItems = []
    @observable SaladAllItems = []

    //Excel Edit Page
    @observable DiningRoomCulinaryTourItems = []// סיור קולינארי
    @observable DiningRoomVeganCulinaryTourItems = [] // סיור קולינארי טבעוני
    @observable DiningRoomSoupItems = [] // מרקים
    @observable DiningRoomCaughtInTheNetItems = [] //נתפס ברשת
    @observable DiningRoomRoastedOnTheGrillItems = [] //צלוי על הָאַסְכָּלָה
    @observable DiningRoomKokurikoItems = [] //קוקוריקו
    @observable DiningRoomMammothDishesItems = [] //תבשילי מאמות
    @observable DiningRoomVegItems = [] //צמחוני טבעוני
    @observable DiningRoomExtrasItems = [] //פחמימה
    @observable DiningRoomSaladItems = [] //סלטים
    @observable DiningRoomDessertsItems = [] //משהו לקינוח


    //הדרן
    @observable ExcelHadranCloseDates = []
    @observable ExcelHadranCulinaryTourItems = [] // סיור קולינרי
    @observable ExcelHadranVeganCulinaryTourItems = [] // סיור קולינרי טבעוני
    @observable ExcelHadranCaughtInTheNetItems = [] //נתפס ברשת
    @observable ExcelHadranRoastedOnTheGrillItems = [] //צלוי על הָאַסְכָּלָה
    @observable ExcelHadranKokurikoItems = [] //קוקוריקו
    @observable ExcelHadranVegetableStewsItems = [] //תבשילי ירקות
    @observable ExcelHadranVegItems = [] //צמחוני טבעוני
    @observable ExcelHadranPachmemaItems = [] //פחמימה
    @observable ExcelHadranSaladItems = [] //סלטים
    @observable ExcelHadranDessertsItems = [] //משהו לקינוח

    @observable FoodcourtPastaFuriFirstDish = []
    @observable FoodcourtPastaFuriPastasTypes = []
    @observable FoodcourtPastaFuriSaucesTypes = []
    @observable FoodcourtPastaFuriMeatExtra = []

    @observable FoodcourtHummusDishesToChoose = []
    @observable FoodcourtHummusExtra = []

    @observable FoodcourtAdisonHamburger = []
    @observable FoodcourtAdisonBunExtra = []
    @observable FoodcourtAdisonExtra = []

    @observable StekiyaMeat = []
    @observable StekiyaExtra = []

    @observable SaladVegetables = []
    @observable SaladSauces = []
    @observable ExcelSaladHelbon = []
    @observable ExcelSaladExtra = []


    @observable ExcelCoffeShopDeal = []
    @observable ExcelBussinesFirst = []
    @observable ExcelBussinesMain = []
    @observable ExcelBussinesDrink = []
    @observable ExcelBussinesDessert = []
    @observable ExcelCoffeShopHotDrink = []


    //Excel Edit GlutenFree
    @observable ExcelGlutenfreeDinningRoom = []
    @observable ExcelGlutenfreeEdison = []

    //Excel Edit Page

    constructor() {
        makeAutoObservable(this);
        this.GetNotifications()
        this.GetDiningRooms()
        this.GetSiteSetting()
    };


    @action ClearAllExcelUpdate() {
        this.DiningRoomCulinaryTourItems = []// סיור קולינארי
        this.DiningRoomVeganCulinaryTourItems = [] // סיור קולינארי טבעוני
        this.DiningRoomSoupItems = [] // מרקים
        this.DiningRoomCaughtInTheNetItems = [] //נתפס ברשת
        this.DiningRoomRoastedOnTheGrillItems = [] //צלוי על הָאַסְכָּלָה
        this.DiningRoomKokurikoItems = [] //קוקוריקו
        this.DiningRoomMammothDishesItems = [] //תבשילי מאמות
        this.DiningRoomVegItems = [] //צמחוני טבעוני
        this.DiningRoomExtrasItems = [] //פחמימה
        this.DiningRoomSaladItems = [] //סלטים
        this.DiningRoomDessertsItems = [] //משהו לקינוח

        this.ExcelHadranCloseDates = []
        this.ExcelHadranCulinaryTourItems = [] // סיור קולינרי
        this.ExcelHadranVeganCulinaryTourItems = [] // סיור קולינרי טבעוני
        this.ExcelHadranCaughtInTheNetItems = [] //נתפס ברשת
        this.ExcelHadranRoastedOnTheGrillItems = [] //צלוי על הָאַסְכָּלָה
        this.ExcelHadranKokurikoItems = [] //קוקוריקו
        this.ExcelHadranVegetableStewsItems = [] //תבשילי ירקות
        this.ExcelHadranVegItems = [] //צמחוני טבעוני
        this.ExcelHadranPachmemaItems = [] //פחמימה
        this.ExcelHadranSaladItems = [] //סלטים
        this.ExcelHadranDessertsItems = [] //משהו לקינוח

        this.FoodcourtPastaFuriFirstDish = []
        this.FoodcourtPastaFuriPastasTypes = []
        this.FoodcourtPastaFuriSaucesTypes = []
        this.FoodcourtPastaFuriMeatExtra = []

        this.FoodcourtHummusDishesToChoose = []
        this.FoodcourtHummusExtra = []

        this.FoodcourtAdisonHamburger = []
        this.FoodcourtAdisonBunExtra = []
        this.FoodcourtAdisonExtra = []

        this.StekiyaMeat = []
        this.StekiyaExtra = []

        this.SaladVegetables = []
        this.SaladSauces = []
        this.ExcelSaladHelbon = []
        this.ExcelSaladExtra = []

        this.ExcelGlutenfreeDinningRoom = []
        this.ExcelGlutenfreeEdison = []

        this.ExcelMhadrinDishToSelect = []
    }

    @action RefreshAll() {
        this.GetNotifications()
        this.GetDishes(0)
        this.GetDiningRooms()
        // this.GetSiteSetting()
    }


    @action
    GetSiteSetting() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.PageId = '50'
        this.loading = true
        this.GetSettingLoading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${this.PageId}?skip_cache=1`)
            .then(res => {
                // console.log(res.data)
                runInAction(() => {
                    this.Setting = res.data
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPage ERR: ' + err)
                });
            })
    };


    @action
    delete_post(item_id) {
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.page_id = item_id
        const wordPressSiteUrl = clientConfig.siteUrl;

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/delete_post`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {

            })
            .catch(err => {

            })
    }

    @action
    GetNotifications() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/notifications/?skip_cache=1&acf_format=standard`)
            .then(res => {

                runInAction(() => {
                    this.loading = false
                    let List = []
                    const today = new Date();
                
                    res.data.sort((a, b) => (parseInt(a.acf.order) > parseInt(b.acf.order)) ? 1 : -1)

                    res.data.map((item, index) => {

                        if (item.acf.start_date == '') {
                            List.push(item)
                        }
                        if (item.acf.start_date !== '') {

                            const startDateMomentObject = moment(item.acf.start_date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
                            let startDateObject = startDateMomentObject.toDate(); // convert moment.js object to Date object
                            const startTime = item.acf.start_time

                            if (startTime !== '') {
                                const startTimeSlpit = startTime.split(':');
                                startDateObject.setHours(startTimeSlpit[0], startTimeSlpit[1])
                            }

                            if (startDateObject <= today) {
                                List.push(item)
                            }
                        }
                        if (item.acf.end_date !== '') {

                            const endDateMomentObject = moment(item.acf.end_date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
                            let endDateObject = endDateMomentObject.toDate(); // convert moment.js object to Date object
                            const EndTime = item.acf.end_time
                            if (EndTime !== '') {
                                const EndTimeSlpit = EndTime.split(':');
                                endDateObject.setHours(EndTimeSlpit[0], EndTimeSlpit[1])
                            }

                            if (endDateObject <= today) {
                                List = List.filter(Listitem => Listitem.id !== item.id);
                                console.log(item.id)
                                this.delete_post(item.id)
                            }
                        }

                        const RemoveDuplicates = [...new Set(List)];
                        List = RemoveDuplicates
                        return null
                    })
                    this.NotificationsList = List
                    if (document.getElementById('set_notifications')) {
                        document.getElementById('set_notifications').click()
                    }
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('NotificationList ERR: ' + err)
                });
            })
    };


    @action
    GetDishes(offset) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const PerPage = 100
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/dishes?_embed&acf_format=standard&per_page=${PerPage}&offset=${offset}`)
            .then(res => {
                runInAction(() => {
                    const Arr1 = this.Dishes
                    const Arr2 = res.data
                    const Arr3 = Arr1.concat(Arr2);
                    if (res.data.length == PerPage) {
                        const newOffset = offset + PerPage
                        this.Dishes = Arr3
                        this.GetDishes(newOffset)
                        return
                    }
                    this.Dishes = Arr3
                    // console.log(toJS(this.Dishes))
                });
            })
            .catch(err => {
                runInAction(() => {

                    console.log('LoadPage ERR: ' + err)
                });
            })
    };


    @action
    GetDiningRooms() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        this.DiningRoomsLoading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/DiningRoom?per_page=100`)
            .then(res => {
                let DiningRoomsList = []
                res.data.map(item => {
                    DiningRoomsList.push(
                        {
                            value: item.name,
                            label: item.name,
                            id: item.id,
                            icon: item.description
                        }
                    )
                    return null
                })
                runInAction(() => {
                    this.DiningRooms = DiningRoomsList
                    this.DiningRoomsLoading = false
                });
            })
            .catch(err => {
                runInAction(() => {
                    console.log('LoadPage ERR: ' + err)
                    this.DiningRoomsLoading = false
                });
            })
    };



    @action
    GetHomePage() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        console.log(wordPressSiteUrl)
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const Urllang = urlParams.get('lang')
        let lang = '&lang=he'
        if (Urllang !== null) {
            lang = '&lang=' + Urllang
        }

        // 6496 EN 6724
        this.PageId = '6496'
        if (Urllang === 'en') {
            this.PageId = '6724'
        }

        this.loading = true
        this.GetSettingLoading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/6496?skip_cache=1&acf_format=standard`)
            .then(res => {
                // console.log(res.data)
                runInAction(() => {
                    this.HomePageContent = res.data.content.rendered
                    this.HomePageACF = res.data.acf
                    this.HomePageContentReady = true
                    this.loading = false
                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPage ERR: ' + err)
                });
            })
    };



    @action
    GetAboutPage() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const Urllang = urlParams.get('lang')
        let lang = '&lang=he'
        if (Urllang !== null) {
            lang = '&lang=' + Urllang
        }

        // HE 6977 EN 6990
        this.PageId = '6977'
        if (Urllang === 'en') {
            this.PageId = '6990'
        }

        this.loading = true
        this.GetSettingLoading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${this.PageId}?skip_cache=1&acf_format=standard`)
            .then(res => {
                console.log(res.data)
                runInAction(() => {
                    this.AboutPageACF = res.data.acf
                    this.loading = false

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false

                    console.log('LoadPage ERR: ' + err)
                });
            })
    };

    @action
    GetSingleProject(id) {
        this.SingleProject = []
        const wordPressSiteUrl = clientConfig.siteUrl;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const Urllang = urlParams.get('lang')
        let lang = '&lang=he'
        if (Urllang !== null) {
            lang = '&lang=' + Urllang
        }
        this.loadingSingleProject = true
        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/projects/${id}?acf_format=standard`)
            .then(res => {
                // console.log(res.data)

                runInAction(() => {
                    this.SingleProject = res.data
                    this.loadingSingleProject = false
                    this.loading = false

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loadingSingleProject = false

                    this.loading = false
                    console.log('LoadPage ERR: ' + err)
                });
            })

    }


};



export default new Pages();
